import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import React from 'react';

const styles = (theme: Theme) => ({
  wrapper: {
    position: 'absolute' as 'absolute',
    width: '100%',
    zIndex: 100,
    bottom: 0,
    textAlign: 'center' as 'center',
  },
  button: {
    width: 30,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    cursor: 'pointer',
    userSelect: 'none' as 'none',
    position: 'absolute' as 'absolute',
    bottom: '50%',
    color: theme.palette.secondary.main,
  },
  left: {
    left: 0,
  },
  right: {
    right: 0,
  },
});

interface Props extends WithStyles<typeof styles, true> {
  index: number;
  total: number;
  loop: boolean;
  prevHandler: () => void;
  nextHandler: () => void;
}

class CarouselArrows extends React.Component<Props, {}> {
  public render() {
    const { classes, index, total, loop, prevHandler, nextHandler } = this.props;

    return (
      <div className={classes.wrapper}>
        {(loop || index !== 0) && (
          <div className={classnames(classes.button, classes.left)} onClick={prevHandler}>
            <ChevronLeftIcon fontSize="large" />
          </div>
        )}
        {(loop || index !== total - 1) && (
          <div className={classnames(classes.button, classes.right)} onClick={nextHandler}>
            <ChevronRightIcon fontSize="large" />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CarouselArrows);
