import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import InfoIcon from '@material-ui/icons/Info';

import BackgroundImage from '../components/BackgroundImage';
import FlexGrid from '../components/FlexGrid';
import HouseDetailsText from '../components/HouseDetailsText';
import LoadingIndicator from '../components/LoadingIndicator';
import MainGridContainer from '../components/MainGridContainer';
import withQueryAndBackground, { WithQuery } from '../components/PageQuery';
import PaymentDate from '../components/PaymentDate';
import PaymentHistory from '../components/PaymentHistory';
import PaymentMethodList from '../components/PaymentMethodList';
import PaymentPrompt from '../components/PaymentPrompt';
import RoundedPaper from '../components/RoundedPaper';

import PayNow, { IPayNowFragment } from '../components/PayNow';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import config from '../config';

import PaymentsPageQuery, { PaymentsPageQueryData } from './PaymentsPageQuery';
import { Grid, Dialog, Icon } from '@material-ui/core';

const styles = (theme: Theme) => ({
  lowerCard: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing.unit * 22,
    },
  },
  history: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  payment: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  spacer: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  smallSpacer: {
    height: theme.spacing.unit * 2,
  },
});

interface Props extends WithStyles<typeof styles>, WithQuery<PaymentsPageQueryData> { }

class PaymentsPage extends React.Component<Props, {}> {
  public state = {
    opened: false
  };
  public render() {
    const { backgroundData, classes, data, error, loading } = this.props;
    return (
      <>
        {data &&
          <Dialog open={this.state.opened} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
            <PayNow {...data.resident} onClose={this.handleClose} />
          </Dialog>
        }
        <BackgroundImage {...backgroundData} />
        <MainGridContainer margin reverse>
          <FlexGrid item xs={12} lg={3}>
            <LoadingIndicator type="emptySpace" loading={loading}>
              {data && <HouseDetailsText {...data.resident} />}
            </LoadingIndicator>
          </FlexGrid>

          <FlexGrid item xs={12} md={6} lg={4} className={classes.payment}>
            <RoundedPaper shadow title="Payment history" className={classes.lowerCard}>
              <LoadingIndicator type="list" loading={loading}>
                {data && <PaymentHistory {...data.resident} />}
              </LoadingIndicator>
            </RoundedPaper>
          </FlexGrid>

          <FlexGrid item xs={12} md={6} lg={3} className={classes.history}>
            <RoundedPaper className={classes.lowerCard} padding shadow marginBottom={20}>
              <LoadingIndicator type="text" loading={loading} length={2}>
                {data && <PaymentPrompt {...data.resident} />}
              </LoadingIndicator>
            </RoundedPaper>
            <RoundedPaper padding shadow>
              <LoadingIndicator type="text" loading={loading} length={2}>
                <Typography align="center" variant="subtitle2" paragraph gutterBottom>My payment day:
                  <Tooltip title="*Per your lease, rent is due on the 1st day of the month, but you have a grace period through the 5th day.
                  Regardless of the day you choose for your automatic payment,
                  your rent will be considered late if it is paid after the 5th day of the month.">
                    <InfoIcon fontSize="small"/>
                  </Tooltip>
                </Typography>
                {data && <PaymentDate {...data.resident} />}
                <div className={classes.smallSpacer} />
                <Typography align="center" variant="subtitle2" paragraph gutterBottom>or</Typography>
                <Button variant="contained" color="primary" onClick={this.openDialog}>Make payment now</Button>
                <div className={classes.smallSpacer} />
                <Typography align="left" variant="subtitle2">Bank Accounts</Typography>
                {data && <PaymentMethodList {...data.resident} />}
                <Link to={config.paths.autopay} style={{ textDecoration: 'none' }}>
                  <Button variant="contained" color="primary">Add new bank account</Button>
                </Link>
              </LoadingIndicator>
            </RoundedPaper>
          </FlexGrid>

          <FlexGrid item xs={12} md={1} lg={1} className={classes.spacer} />
        </MainGridContainer>
      </>
    );
  }
  private handleClose = () => {
    this.setState({
      opened: false,
    });
  };

  private openDialog = () => {
    this.setState({
      opened: true,
    });
  };
}

export default withQueryAndBackground<PaymentsPageQueryData>(PaymentsPageQuery, withStyles(styles)(PaymentsPage));
