import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Slider from '@material-ui/lab/Slider';
import classnames from 'classnames';
import { gql } from 'apollo-boost';
import * as React from 'react';

import { formatAsCurrency } from '../utils';
const styles = (theme: Theme) => ({
  estimates: {
    color: theme.palette.secondary.main,
  },
});

export const CurrentHomeValueTableFragment = gql`
  fragment CurrentHomeValueTableFragment on Home {
    id
    zestimateData {
      id
      value
      lastUpdated
      source
    }
  }
`;
type allowedSources = 'zillow' | 'redfin' | 'house-canary';
export interface ICurrentHomeValueTableFragment {
  zestimateData: {
    id: number;
    value: number;
    lastUpdated: string;
    source: allowedSources;
  }[];
}
interface Props extends WithStyles<typeof styles>, ICurrentHomeValueTableFragment {}

class EquityValueTable extends React.Component<Props, {}> {
  public render() {
    const { classes } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="none" align="right">
              Zillow Estimate
            </TableCell>
            <TableCell padding="none" align="right">
              Redfin Estimate
            </TableCell>
            <TableCell padding="none" align="right">
              Housecanary
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.estimates} color="inherit" align="right" padding="none">
              {this.getEstimateValueOfSource('zillow')}
            </TableCell>
            <TableCell className={classes.estimates} color="inherit" align="right" padding="none">
              {this.getEstimateValueOfSource('redfin')}
            </TableCell>
            <TableCell className={classes.estimates} color="inherit" align="right" padding="none">
              {this.getEstimateValueOfSource('house-canary')}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
  private getEstimateValueOfSource(source: allowedSources) {
    const { zestimateData } = this.props;
    if (!zestimateData) return false;
    const estimate = zestimateData.find(e => e.source === source);
    if (!estimate) return 'Coming Soon!';
    return formatAsCurrency(estimate.value);
  }
}

export default withStyles(styles)(EquityValueTable);
