import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Color from 'color';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { formatAsCurrency, formatAsPercentage } from '../utils';
const styles = (theme: Theme) => ({
  root: {},
  equity: {
    marginTop: theme.spacing.unit * 3,
  },
  doughnutContainer: {
    marginTop: -theme.spacing.unit,
    position: 'relative' as 'relative',
  },
  doughnut: {
    marginTop: -10,
  },
  overlayContainer: {
    display: 'flex',
    position: 'absolute' as 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  overlay: {
    margin: 'auto',
    textAlign: 'center' as 'center',
    color: theme.palette.primary.light,
  },
});

export const MonthlyPaymentChartFragment = gql`
  fragment MonthlyPaymentChartFragment on Resident {
    id
    monthlyPaymentAmount
    savingsBuilder {
      id
      currentVestPercentage
      totalVestPercentage
    }
  }
`;

export interface IMonthlyPaymentChartFragment {
  id: number;
  monthlyPaymentAmount?: number;
  savingsBuilder: {
    currentVestPercentage: number;
    totalVestPercentage: number;
  };
}
interface Props extends WithStyles<typeof styles, true>, IMonthlyPaymentChartFragment { }

class MonthlyPaymentChart extends React.Component<Props, {}> {
  public render() {
    const {
      classes,
      monthlyPaymentAmount,
      savingsBuilder: { currentVestPercentage, totalVestPercentage },
      theme,
    } = this.props;
    return (
      <Grid container>
        <Grid item xs={7}>
          <Typography variant="h2" gutterBottom>
            {formatAsCurrency(monthlyPaymentAmount || -1, false)}
          </Typography>
          <div className={classes.equity}>
            <Typography variant="overline">Purchase Credits</Typography>
            <Typography variant="body2">
              <b>{formatAsPercentage(currentVestPercentage)}</b> out of {formatAsPercentage(totalVestPercentage)}*
            </Typography>
            <Typography variant="body2">* accessible after the cliff period</Typography>
          </div>
        </Grid>
        <Grid item xs={5} className={classes.doughnutContainer}>
          <div className={classes.overlayContainer}>
            <div className={classes.overlay}>
              <Typography variant="h3" color="inherit">
                {formatAsPercentage(currentVestPercentage)}
              </Typography>
              <Typography variant="h6" color="inherit">
                vested
              </Typography>
            </div>
          </div>
          <div className={classes.doughnut}>
            <Doughnut
              options={{
                tooltips: { enabled: false },
                hover: { mode: undefined },
                cutoutPercentage: 85,
                maintainAspectRatio: false,
              }}
              data={{
                datasets: [
                  {
                    data: [currentVestPercentage, totalVestPercentage - currentVestPercentage],
                    backgroundColor: [
                      theme.palette.primary.light,
                      Color(theme.palette.primary.light)
                        .fade(0.6)
                        .desaturate(0.5)
                        .toString(),
                    ],
                  },
                ],
              }}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MonthlyPaymentChart);
