import { grey } from "@material-ui/core/colors";
import red from "@material-ui/core/colors/red";
import { createMuiTheme } from "@material-ui/core/styles";
import Color from "color";

import background from "./images/background.png";

const colors = {
  primary: {
    dark: "#2E2852",
    main: "#6644CC",
    light: "#A26BF9",
    contrastText: "#fff",
  },
  secondary: {
    main: "#6FCF97",
  },
  background: {
    default: "#fff",
  },
};

const createDefaultTransiton = (property: string) =>
  `${property} 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`;

export const gradients = {
  primary: ["#BC9CFF", "#8BA4F9"],
  secondary: ["#6FCF97", "#66D2EA"],
};

export const fonts = {
  primary: "ff-real-text-pro",
  secondary: "ff-real-headline-pro",
};
// We can't put them into the theme without doing https://material-ui.com/guides/typescript/#customization-of-theme

const theme = createMuiTheme({
  palette: {
    ...colors,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  typography: {
    useNextVariants: true,
    fontFamily: [fonts.primary, "Roboto", "Arial", "sans-serif"].join(","),

    h1: {
      color: "#1F2041",
      fontSize: 64,
      fontFamily: fonts.secondary,
    },
    h2: {
      color: "#1F2041",
      fontSize: 40,
      fontFamily: fonts.secondary,
    },
    h3: {
      color: "#1F2041",
      fontSize: 24,
      fontWeight: 700,
      fontFamily: fonts.primary,
    },
    h4: {
      color: "#1F2041",
      fontSize: 16,
      fontWeight: 700,
      fontFamily: fonts.primary,
    },
    h5: {
      color: "#1F2041",
      fontSize: 19,
      fontWeight: 700,
      fontFamily: fonts.primary,
    },
    h6: {
      color: "#1F2041",
      fontSize: 17,
      fontWeight: 700,
      fontFamily: fonts.primary,
    },
    body1: {
      fontFamily: fonts.primary,
      fontSize: 14,
      color: Color("#1F2041")
        .fade(0.25)
        .toString(),
    },
    body2: {
      fontFamily: fonts.primary,
      fontSize: 12,
      lineHeight: "24px",
      color: Color("#1F2041")
        .fade(0.25)
        .toString(),
    },
    button: {
      fontFamily: fonts.primary,
      fontSize: 12,
      fontWeight: 700,
      lineHeight: "24px",
      color: "#ffffff",
    },
    overline: {
      color: "#1F2041",
      fontSize: 10,
      fontWeight: 700,
      fontFamily: fonts.primary,
    },
  },
  props: {},
  spacing: {
    unit: 10,
  },
  overrides: {
    MuiSwitch: {
      switchBase: {
        "& + $bar": {
          background: "#ffffff",
          opacity: "1 !important" as any,
          borderColor: grey[600],
        },
        transition: createDefaultTransiton("transform"),
        "&$checked": {
          transform: "translateX(21px)",
          "& + $bar": {
            borderColor: colors.primary.main,
          },
        },
      },
      bar: {
        borderRadius: 1000,
        width: 40,
        height: 20,
        marginTop: -11,
        marginLeft: -18,
        border: "solid 1px",
        backgroundColor: "#ffffff !important",
        "&:checked": {
          borderColor: colors.primary.main,
        },
      },
      icon: {
        width: 12,
        height: 12,
        backgroundColor: grey[600],
        boxShadow: "none",
      },
      iconChecked: {
        background: `linear-gradient(180deg, ${gradients.primary[0]} 0%, ${
          gradients.primary[1]
        } 100%)`,
        boxShadow: "none",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 1000,
        boxShadow: "none !important",
        transition:
          "background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
      outlined: {
        borderWidth: "2px !important",
      },
      outlinedPrimary: {
        borderColor: colors.primary.main,
        transition:
          "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        color: colors.primary.main,
        "&:hover": {
          color: colors.primary.main,
        },
      },
      contained: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 22,
        paddingRight: 22,
      },
      containedPrimary: {
        backgroundColor: "#BC9CFF",
        background: `linear-gradient(180deg, ${gradients.primary[0]} 0%, ${
          gradients.primary[1]
        } 50%, ${Color(gradients.primary[1]).darken(0.2)})`,
        backgroundSize: "105% 88px", // slight visual bug in chrome necessitates width > 100%
        backgroundPositionY: 0,
        "&:hover": {
          backgroundPositionY: -44,
        },
      },
      containedSecondary: {
        color: "#ffffff",
        backgroundColor: "#6FCF97",
        background: `linear-gradient(180deg, ${gradients.secondary[0]} 0%, ${
          gradients.secondary[1]
        } 50%, ${Color(gradients.secondary[1]).darken(0.2)})`,
        backgroundSize: "105% 88px",
        backgroundPositionY: 0,
        "&:hover": {
          backgroundPositionY: -44,
        },
      },
    },
  },
});

export default theme;
