import { gql } from 'apollo-boost';

import { BackgroundImageFragment, IBackgroundImageFragment } from '../components/BackgroundImage';
import { HouseDetailsFragment, IHouseDetailsFragment } from '../components/HouseDetails';
import { HouseDetailsTextFragment, IHouseDetailsTextFragment } from '../components/HouseDetailsText';

const DetailsPageQuery = gql`
  query DetailsPageQuery {
    resident {
      ...HouseDetailsTextFragment
      ...HouseDetailsFragment
      property {
        ...BackgroundImageFragment
      }
    }
  }
  ${HouseDetailsTextFragment}
  ${HouseDetailsFragment}
  ${BackgroundImageFragment}
`;

export interface DetailsPageQueryData {
  resident: IHouseDetailsTextFragment & IHouseDetailsFragment & { property: IBackgroundImageFragment };
}

export default DetailsPageQuery;
