import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Route, Switch } from 'react-router-dom';
import apolloClient from './apollo/apolloClient';
import AutopayPage from './AutopayPage/AutopayPage';
import NavBar from './components/NavBar';
import config from './config';
import DetailsPage from './DetailsPage/DetailsPage';
import FinancialsPage from './FinancialsPage/FinancialsPage';
import MainPage from './MainPage/MainPage';
import PaymentsPage from './PaymentsPage/PaymentsPage';
import Theme from './Theme';
import TutorialPage from './TutorialPage/TutorialPage';
import { tutorialPages } from './TutorialPage/tutorialPages';

class App extends React.Component {
  public render() {
    return (
      <ApolloProvider client={apolloClient}>
        <MuiThemeProvider theme={Theme}>
          <CssBaseline />
          <Switch>
            <Route path="/hello/" component={this.NavbarWithOnlySupport} />
            <Route path="/" component={NavBar} />
          </Switch>
          <Switch>
            <Route path="/" exact component={MainPage} />
            <Route path={config.paths.details} exact component={DetailsPage} />
            <Route
              path={`${config.paths.tutorial}/:page(${tutorialPages.map(p => p.name).join('|')})?`}
              exact
              component={this.TutorialPageWithParams}
            />
            <Route path={config.paths.payments} exact component={PaymentsPage} />
            <Route path={config.paths.financials} exact component={FinancialsPage} />
            <Route path={config.paths.autopay} exact component={AutopayPage} />
          </Switch>
        </MuiThemeProvider>
      </ApolloProvider>
    );
  }

  private TutorialPageWithParams = ({ match }: { match: { params: { page?: string } } }) => (
    <TutorialPage page={match.params.page} />
  );

  private NavbarWithOnlySupport = () => <NavBar onlySupport />;
}

export default App;
