import Paper from '@material-ui/core/Paper';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) => ({
  root: {
    borderRadius: 4,
  },
  padding: {
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 25,
  },
  grow: {
    flexGrow: 1,
  },
  flex: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
  },
  titleMargin: {
    marginLeft: 35,
  },
  shadow: {
    boxShadow: '0px 10px 20px rgba(31, 32, 65, 0.05)',
  },
  image: {
    width: '100%',
    height: 270,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginBottom: 25,
  },
});

interface Props extends WithStyles<typeof styles> {
  title: string;
  grow: boolean;
  padding: boolean;
  flex: boolean;
  shadow: boolean;
  className: string;
  marginBottom: 0 | 20 | 40 | 60;
  paddingTop: number;
  imageUrl?: string;
}

class RoundedPaper extends React.Component<Props, {}> {
  public static defaultProps = {
    marginBottom: 0 as 0,
    shadow: false,
    padding: false,
    grow: false,
    flex: false,
    title: undefined,
    className: undefined,
    paddingTop: 25,
    imageUrl: undefined,
  };

  public renderHeader() {
    const { title, classes, padding } = this.props;
    return (
      <Typography className={classnames(!padding && classes.titleMargin)} gutterBottom variant="h6">
        {title}
      </Typography>
    );
  }

  public render() {
    const {
      classes,
      children,
      title,
      grow,
      flex,
      padding,
      shadow,
      marginBottom,
      paddingTop,
      className,
      imageUrl,
    } = this.props;
    return (
      <Paper
        elevation={0}
        className={classnames(
          classes.root,
          grow && classes.grow,
          flex && classes.flex,
          shadow && classes.shadow,
          className,
        )}
        style={{
          marginBottom,
          paddingTop,
        }}
      >
        {imageUrl && <div className={classnames(classes.image)} style={{ backgroundImage: `url(${imageUrl}` }} />}

        <div className={classnames(grow && classes.grow, flex && classes.flex, padding && classes.padding)}>
          {title && this.renderHeader()}
          {children}
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(RoundedPaper);
