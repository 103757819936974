/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * An enumeration.
 */
export enum InvoiceLineType {
  HOA = "HOA",
  INSURANCE = "INSURANCE",
  MONTHLY_PAYMENT = "MONTHLY_PAYMENT",
  OFFER = "OFFER",
  OPTION = "OPTION",
  OTHER = "OTHER",
  PROPERTY_TAX = "PROPERTY_TAX",
  REPAIRS_MAINTENANCE = "REPAIRS_MAINTENANCE",
  REWARDS = "REWARDS",
}

/**
 * An enumeration.
 */
export enum RTP_Type {
  MONTH = "MONTH",
  QUARTER = "QUARTER",
  YEAR = "YEAR",
}

/**
 * An enumeration.
 */
export enum ResidentStatus {
  MOVED_IN = "MOVED_IN",
  MOVED_OUT_DEFAULT = "MOVED_OUT_DEFAULT",
  MOVED_OUT_OWNED = "MOVED_OUT_OWNED",
  OFFER = "OFFER",
  ONBOARDING = "ONBOARDING",
  PROSPECT = "PROSPECT",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
