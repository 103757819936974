import { gql } from 'apollo-boost';

const TutorialPageQuery = gql`
  query TutorialPageQuery {
    resident {
      id
      autopayConfigured
      welcomeScreens {
        name
        imageUrl
      }
    }
  }
`;
export interface TutorialPageQueryData {
  resident: {
    autopayConfigured: boolean;
    welcomeScreens: {
      name: string;
      imageUrl: string;
    }[];
  };
}

export default TutorialPageQuery;
