import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';

import BackgroundImage from '../components/BackgroundImage';
import CurrentHomeValueTable from '../components/CurrentHomeValueTable';
import FlexGrid from '../components/FlexGrid';
import HouseDetailsText from '../components/HouseDetailsText';
import LoadingIndicator from '../components/LoadingIndicator';
import MainGridContainer from '../components/MainGridContainer';
import MonthlyPaymentBreakdown from '../components/MonthlyPaymentBreakdown';
import OwnershipAccrualChart from '../components/OwnershipAccrualChart';
import withQueryAndBackground, { WithQuery } from '../components/PageQuery';
import RightToPurchaseChart from '../components/RightToPurchaseChart';
import RoundedPaper from '../components/RoundedPaper';

import FinancialDocuments from './FinancialDocuments';
import FinancialsPageQuery, { FinancialsPageQueryData } from './FinancialsPageQuery';

const styles = (theme: Theme) => ({
  lowerCard: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing.unit * 22,
    },
  },
  netWorth: {
    [theme.breakpoints.down('md')]: {
      // order: 1,
    },
  },
  homeValue: {
    [theme.breakpoints.down('md')]: {
      // order: 3,
    },
  },
  projections: {
    [theme.breakpoints.down('md')]: {
      // order: 2,
    },
  },
});

interface Props extends WithStyles<typeof styles>, WithQuery<FinancialsPageQueryData> { }

class FinancialsPage extends React.Component<Props, {}> {
  public render() {
    const { backgroundData, classes, data, error, loading } = this.props;
    const isFmv = data && data.resident.savingsBuilder.planType == 'Fair Market Value';
    return (
      <>
        <BackgroundImage {...backgroundData} />
        <MainGridContainer margin reverse>
          <FlexGrid item xs={12} lg={3}>
            <LoadingIndicator type="emptySpace" loading={loading}>
              {data && <HouseDetailsText {...data.resident} />}
            </LoadingIndicator>
          </FlexGrid>
          <FlexGrid item xs={12} lg={8} className={classes.homeValue}>
            <RoundedPaper shadow grow padding title="Vesting Schedule" className={classes.lowerCard}>
              <LoadingIndicator type="textAndLinearChart" loading={loading}>
                {data && <OwnershipAccrualChart small {...data.resident.savingsBuilder} />}
              </LoadingIndicator>
            </RoundedPaper>
          </FlexGrid>
          {!isFmv && <FlexGrid item xs={12} lg={3} />}

          {!isFmv && <FlexGrid item xs={12} lg={8} className={classes.netWorth}>
            <RoundedPaper shadow grow padding title="Right to Purchase" >
              <LoadingIndicator type="textAndLinearChart" loading={loading}>
                {data && <RightToPurchaseChart small {...data.resident.savingsBuilder} />}
              </LoadingIndicator>
            </RoundedPaper>
          </FlexGrid>}
          <FlexGrid item xs={12} lg={3} />
          <FlexGrid item xs={12} lg={4} className={classes.netWorth}>
            <RoundedPaper shadow grow padding title="Monthly Payment Breakdown">
              <LoadingIndicator type="textAndLinearChart" loading={loading}>
                {data && <MonthlyPaymentBreakdown {...data.resident} />}
              </LoadingIndicator>
            </RoundedPaper>
          </FlexGrid>
          {/* <FlexGrid item xs={12} lg={4} className={classes.netWorth}>
            <RoundedPaper shadow grow padding title="Current Home Value">
              <LoadingIndicator type="text" loading={loading}>
                {data && <CurrentHomeValueTable {...data.resident.property.home} />}
              </LoadingIndicator>
            </RoundedPaper>
          </FlexGrid> */}
          <FlexGrid item xs={12} lg={4} className={classes.projections}>
            <RoundedPaper shadow title="Financial documents">
              <LoadingIndicator type="list" loading={loading}>
                {data && <FinancialDocuments {...data.resident} />}
              </LoadingIndicator>
            </RoundedPaper>
          </FlexGrid>

          <FlexGrid item xs={12} lg={3} />

        </MainGridContainer>
      </>
    );
  }
}

export default withQueryAndBackground<FinancialsPageQueryData>(FinancialsPageQuery, withStyles(styles)(FinancialsPage));
