import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { gql } from 'apollo-boost';
import * as React from "react";
import { Mutation } from "react-apollo";
import { Modal } from 'antd';

const { confirm } = Modal;

export const PaymentDateFragment = gql`
  fragment PaymentDateFragment on Resident {
    id
    paymentDayOfMonth
    nextPaymentMonth
    nextPaymentMonthOptions
  }
`;

export interface IPaymentDateFragment {
  paymentDayOfMonth: number;
  nextPaymentMonthOptions: [string];
}

const PaymentDateChangeMutation = gql`
  mutation ChangePaymentDayOfMonth($newPaymentDayOfMonth: Int!) {
    changePaymentDayOfMonth(newPaymentDayOfMonth: $newPaymentDayOfMonth) {
      resident {
        id
        ...PaymentDateFragment
      }
    }
  }
  ${PaymentDateFragment}
`;

export interface PaymentDateChangeMutationVariables {
  newPaymentDayOfMonth: number;
}

export interface PaymentDateChangeMutationData {
  resident: IPaymentDateFragment;
}

class PaymentDate extends React.Component<IPaymentDateFragment, {}> {
  public handleChange = (
    mutate: ((
      { variables }: { variables: PaymentDateChangeMutationVariables },
    ) => void),
  ) => (event: React.ChangeEvent<HTMLSelectElement>) => {
    confirm({
      title: 'Confirm',
      content: 'Are you sure you want to change Payment Date?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        mutate({ variables: { newPaymentDayOfMonth: Number(event.target.value) } });
      }
    });
  };

  public render() {
    const { paymentDayOfMonth } = this.props;
    return (
      <Mutation mutation={PaymentDateChangeMutation}>
        {(
          setPaymentDate: ((
            { variables }: { variables: PaymentDateChangeMutationVariables },
          ) => void),
        ) => (
          <Select
            value={paymentDayOfMonth}
            onChange={this.handleChange(setPaymentDate)}
            input={
              <OutlinedInput
                labelWidth={0}
                name="dayOfMonth"
                id="outlined-age-simple"
              />
            }
          >
            {[...(Array(5) as any).keys()].map(n => (
              <MenuItem key={n} value={n + 1}>
                {n + 1}
                {n === 0 ? "st" : n === 1 ? "nd" : n === 2 ? "rd" : "th"} day of
                the month
              </MenuItem>
            ))}
          </Select>
        )}
      </Mutation>
    );
  }
}

export default PaymentDate;
