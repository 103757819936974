import { gql } from 'apollo-boost';

import { BackgroundImageFragment, IBackgroundImageFragment } from '../components/BackgroundImage';
import { HouseDetailsTextFragment, IHouseDetailsTextFragment } from '../components/HouseDetailsText';
import { IPaymentDateFragment, PaymentDateFragment } from '../components/PaymentDate';
import { IPaymentHistoryFragment, PaymentHistoryFragment } from '../components/PaymentHistory';
import { IPaymentMethodListFragment, PaymentMethodListFragment } from '../components/PaymentMethodList';
import { IPaymentPromptFragment, PaymentPromptFragment } from '../components/PaymentPrompt';

const PaymentsPageQuery = gql`
  query PaymentsPageQuery {
    resident {
      ...HouseDetailsTextFragment
      ...PaymentDateFragment
      ...PaymentHistoryFragment
      ...PaymentMethodListFragment
      ...PaymentPromptFragment
      property {
        ...BackgroundImageFragment
      }
    }
  }
  ${HouseDetailsTextFragment}
  ${PaymentDateFragment}
  ${PaymentHistoryFragment}
  ${PaymentMethodListFragment}
  ${PaymentPromptFragment}
  ${BackgroundImageFragment}
`;

export interface PaymentsPageQueryData {
  resident: IPaymentMethodListFragment &
  IHouseDetailsTextFragment &
  IPaymentPromptFragment &
  IPaymentHistoryFragment &
  IPaymentDateFragment & {
    property: IBackgroundImageFragment;
  };
}

export default PaymentsPageQuery;
