import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

import { gradients } from '../Theme';

const styles = (theme: Theme) => ({
  container: {
    height: 236,
    justifyContent: 'flex-end',
    position: 'relative' as 'relative',
  },
  name: {
    marginLeft: theme.spacing.unit * 4,
  },
  population: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 4,
  },
  ribbon: {
    position: 'absolute' as 'absolute',
    top: 37,
    left: 0,
    width: 142,
    height: 44,
    color: '#ffffff',
    backgroundColor: '#6FCF97',
    background: `linear-gradient(180deg, ${gradients.secondary[0]} 0%, ${gradients.secondary[1]})`,
    textTransform: 'uppercase' as 'uppercase',
    lineHeight: '44px',
    fontSize: '10pt',
    textAlign: 'center' as 'center',
    borderTopRightRadius: '22px',
    borderBottomRightRadius: '22px',
  },
  ribbonText: {
    color: '#ffffff',
    textTransform: 'uppercase' as 'uppercase',
    lineHeight: '44px',
    fontSize: '10pt',
    textAlign: 'center' as 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  name: string;
  imageUrl: string;
  population: string;
}

class Neighborhood extends React.Component<Props, {}> {
  public render() {
    const { classes, name, imageUrl, population } = this.props;
    return (
      <Grid
        container
        direction="column"
        className={classes.container}
        style={{
          background: `radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,0.95) 65%), url(${imageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className={classes.ribbon}>
          <Typography className={classes.ribbonText}>Neighborhood</Typography>
        </div>
        <Grid item>
          <Typography variant="h5" className={classes.name}>
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.population}>population: {population}</Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Neighborhood);
