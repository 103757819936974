import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

const styles = (theme: Theme) => ({
  container: {
    padding: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
    boxSizing: 'border-box' as 'border-box',
    flexGrow: 1,
  },
  label: {
    textTransform: 'uppercase' as 'uppercase',
  },
});

interface Props extends WithStyles<typeof styles> {
  big?: boolean;
  label: string;
  value?: string;
}

class HousePropertyText extends React.Component<Props, {}> {
  public render() {
    const { classes, label, big, value, children } = this.props;
    return (
      <Grid item xs={big ? 12 : 6} sm={big ? 12 : 4} md={big ? 12 : 6} className={classes.container}>
        <Typography variant="h4" className={classes.label}>
          {label}
        </Typography>
        {value ? <Typography>{value}</Typography> : children}
      </Grid>
    );
  }
}

export default withStyles(styles)(HousePropertyText);
