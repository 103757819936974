import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { gql } from 'apollo-boost';
import * as React from 'react';
import moment from 'moment';
import FlexGrid from './FlexGrid';

import { formatAsCurrency } from '../utils';

export const DB_DATE_FORMAT = 'YYYY-MM-DD';

const styles = (theme: Theme) => ({
  center: {
    textAlign: 'center' as 'center',
  },
  buttonWrapper: {
    textAlign: 'center' as 'center',
  },
  paymentValue: {
    color: theme.palette.primary.light,
  },
  button: {
    margin: theme.spacing.unit,
    marginTop: theme.spacing.unit * 4,
    paddingRight: 40,
  },
  icon: {
    marginRight: 10,
  },
  buttonsContainer: {
    margin: -theme.spacing.unit,
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
  },

  futureGain: {
    color: '#8f90a0',
  },
  divider: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit,
  },
});

export const PaymentPromptFragment = gql`
  fragment PaymentPromptFragment on Resident {
    id
    monthlyPaymentAmount
    proratedPaymentAmount
    leaseStartDate
    billingCycleStartDate
  }
`;

export interface IPaymentPromptFragment {
  id: number;
  monthlyPaymentAmount: number;
  proratedPaymentAmount: number;
  leaseStartDate: string;
  billingCycleStartDate: string;
}

interface Props extends WithStyles<typeof styles>, IPaymentPromptFragment { }

class PaymentPrompt extends React.Component<Props, {}> {
  public render() {
    const { classes, monthlyPaymentAmount, proratedPaymentAmount, leaseStartDate, billingCycleStartDate } = this.props;
    return (
      <>
        {!!proratedPaymentAmount &&
          <FlexGrid item xs={12} lg={12}>
            <Typography variant="h2" gutterBottom className={classes.paymentValue}>
              {formatAsCurrency(proratedPaymentAmount)}
            </Typography>
            <Typography variant="subtitle2">Prorated first month payment due on
          {' '}{moment(leaseStartDate, DB_DATE_FORMAT).format('MMMM DD, YYYY')}</Typography>
            <Divider className={classes.divider} />
          </FlexGrid>
        }
        <FlexGrid item xs={12} lg={12}>
          <Typography variant="h2" gutterBottom className={classes.paymentValue}>
            {formatAsCurrency(monthlyPaymentAmount)}
          </Typography>
          <Typography variant="subtitle2">Regular monthly payment starting on
        {' '}{moment(billingCycleStartDate, DB_DATE_FORMAT).format('MMMM DD, YYYY')}</Typography>
        </FlexGrid>
      </>
    );
  }
}

export default withStyles(styles)(PaymentPrompt);
