import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { gql } from 'apollo-boost';
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import { Mutation } from 'react-apollo';
import { useMutation } from 'react-apollo';
import Divider from '@material-ui/core/Divider';
import { right } from 'styled-system';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import client from '../apollo/apolloClient';

export const PaymentMethodListFragment = gql`
  fragment PaymentMethodListFragment on Resident {
    id
    paymentMethods {
      id
      bankName
      last4
      routingNumber
      isDefault
    }
  }
`;

export interface IPaymentMethodListFragment {
  id: number;
  paymentMethods: {
    id: string;
    last4: string;
    routingNumber: string;
    bankName: string;
    isDefault: boolean;
  }[];
}

const PaymentMethodChangeMutation = gql`
  mutation ChangeDefaultPaymentMethod($newPaymentMethodId: ID!) {
    changeDefaultPaymentMethod(newPaymentMethodId: $newPaymentMethodId) {
      resident {
        id
        ...PaymentMethodListFragment
      }
    }
  }
  ${PaymentMethodListFragment}
`;

const PaymentMethodDeleteMutation = gql`
  mutation DeletePaymentMethod($deletedPaymentMethodId: ID!) {
    deletePaymentMethod(deletedPaymentMethodId: $deletedPaymentMethodId) {
      resident {
        id
        ...PaymentMethodListFragment
      }
    }
  }
  ${PaymentMethodListFragment}
`;

export interface PaymentMethodChangeMutationVariables {
  newPaymentMethodId: string;
}

export interface PaymentMethodChangeMutationData {
  resident: IPaymentMethodListFragment;
}
type State = { isDeleting: boolean, open: boolean, isDefault: boolean, selectedMethodId: string };

async function deletePM(deletedPaymentMethodId: number) {
  await client.mutate({
    mutation: PaymentMethodDeleteMutation,
    variables: {
      deletedPaymentMethodId
    },
  });
}

class PaymentMethodList extends React.Component<IPaymentMethodListFragment, State> {
  constructor(props: IPaymentMethodListFragment) {
    super(props);
    this.state = {
      isDeleting: false,
      open: false,
      isDefault: false,
      selectedMethodId: ""
    }
  }

  public handleChange = (mutate: ({ variables }: { variables: PaymentMethodChangeMutationVariables }) => void) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (this.state.isDeleting) {
      return;
    }
    mutate({ variables: { newPaymentMethodId: event.target.id } });
  };

  public handleClose = () => {
    this.setState({ open: false });
    this.setState({ isDeleting: false });
  };

  public handleDelete = (deletedPaymentMethodId: string) => {
    deletePM(parseInt(atob(deletedPaymentMethodId).split(":")[1]));
    this.setState({ open: false });
    this.setState({ isDeleting: false });
  };

  public render() {
    const { paymentMethods } = this.props;
    const defaultMethod = paymentMethods.find(method => method.isDefault);
    return (
      <>
        <Mutation mutation={PaymentMethodChangeMutation}>
          {(setPaymentMethod: ({ variables }: { variables: PaymentMethodChangeMutationVariables }) => void) => (
            <RadioGroup value={defaultMethod ? defaultMethod.id.toString() : ''}>
              {paymentMethods.map(method => (
                <FormControlLabel
                  key={method.id}
                  value={method.id.toString()}
                  style={{ paddingBottom: 10 }}
                  control={<Radio id={method.id} onChange={!this.state.isDeleting ? this.handleChange(setPaymentMethod) : undefined} color="secondary" />}
                  label={<>
                    <Typography variant="caption">
                      {method.bankName} ({''}
                      <a
                        onClick={(event) => {
                          event.stopPropagation();
                          this.setState({ isDeleting: true });
                          this.setState({ open: true, isDefault: method.isDefault, selectedMethodId: method.id });
                        }}
                      >
                        Remove
                      </a>
                      <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        id={method.id}
                      >
                        <>
                          <DialogTitle id="alert-dialog-title-1">{"Do you want to remove the autopay account?"}</DialogTitle>
                          <DialogContent>
                            {!this.state.isDefault ? (
                              <DialogContentText id="alert-dialog-description-1">
                                This will stop any automatic payments that have been enabled for this bank account.
                                Are you sure you want to continue?
                              </DialogContentText>
                            ) : (
                                <DialogContentText id="alert-dialog-description-1">
                                  Sorry, you cannot remove the only linked account. Please add another bank account and then remove this one.
                                </DialogContentText>
                              )}
                          </DialogContent>
                          {!this.state.isDefault ? (
                            <DialogActions>
                              <Button onClick={this.handleClose} color="primary">
                                No
                              </Button>
                              <Button id={this.state.selectedMethodId} onClick={() => { this.handleDelete(this.state.selectedMethodId) }} color="primary" autoFocus>
                                Yes
                              </Button>
                            </DialogActions>) : (
                              <Button onClick={this.handleClose} color="primary">
                                OK
                              </Button>
                            )}
                        </>
                      </Dialog>)
                    </Typography>
                    <Typography>
                      Routing: {method.routingNumber} <br />
                      Account number: *******{method.last4}
                    </Typography>
                  </>
                  }
                />
              ))}
            </RadioGroup>
          )}
        </Mutation>
      </>
    );
  }
}

export default PaymentMethodList;
