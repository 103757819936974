import { gql } from 'apollo-boost';
import { IPaymentDateFragment, PaymentDateFragment } from '../components/PaymentDate';
import { IPaymentMethodListFragment, PaymentMethodListFragment } from '../components/PaymentMethodList';
import { IPaymentPromptFragment, PaymentPromptFragment } from '../components/PaymentPrompt';
import {
  IMonthlyPaymentBreakdownFragment,
  MonthlyPaymentBreakdownFragment,
} from "../components/MonthlyPaymentBreakdown";

export const AutopayFragment = gql`
  fragment AutopayFragment on Resident {
    id
    status
    plaidLinkToken
    autopayConfigured
    monthlyPaymentAmount
    hasGivenAutopayConsent
  }
`;

const AutopayPageQuery = gql`
  query AutopayPageQuery {
    user {
      id
      userId
      username
      firstName
      lastName
    }
    resident {
      id
      ...AutopayFragment
      ...PaymentDateFragment
      ...PaymentMethodListFragment
      ...PaymentPromptFragment
      ...MonthlyPaymentBreakdownFragment
    }
  }
  ${AutopayFragment}
  ${PaymentDateFragment}
  ${PaymentMethodListFragment}
  ${PaymentPromptFragment}
  ${MonthlyPaymentBreakdownFragment}
`;

export interface AutopayPageQueryData {
  user: {
    id: string;
    userId: number;
    username: string;
    firstName: string;
    lastName: string;
  };
  resident: IPaymentMethodListFragment &
  IPaymentPromptFragment &
  IPaymentDateFragment &
  IMonthlyPaymentBreakdownFragment & {
    hasGivenAutopayConsent: boolean;
    autopayConfigured: boolean;
    plaidLinkToken: string;
    status: string;
    monthlyPaymentAmount?: number;
  };
}

export default AutopayPageQuery;
