import { ListItemSecondaryAction } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { Mutation } from 'react-apollo';

import NotificationDetails, { INotificationDetailsFragment } from './NotificationDetails';

const styles = (theme: Theme) => ({
  root: {
    margin: 25,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 4,
    borderRadius: 4,
    width: 'auto',
  },
  deleteButton: {
    right: theme.spacing.unit * 2,
  },
  primaryTypography: {
    fontWeight: 700,
  },
  secondaryTypography: {
    fontSize: 14,
  },
  avatar: {
    borderRadius: 4,
    height: 48,
    width: 56,
  },
  divider: {
    marginLeft: 35,
    marginRight: 35,
  },
  snackbarUndoButton: {
    color: theme.palette.secondary.main,
  },
});

export const NotificationItemFragment = gql`
  fragment NotificationItemFragment on Notification {
    id
    body
    coverImageUrl
    ctaLabel
    ctaUrl
    dismissed
    imageUrl
    isNew
    subject
    title
  }
`;

const DismissNotificationMutation = gql`
  mutation DismissNotification($notificationId: ID!, $revert: Boolean!) {
    dismissNotification(notificationId: $notificationId, revert: $revert) {
      resident {
        id
      }
    }
  }
`;

export interface DismissNotificationMutationVariables {
  notificationId: string;
  revert: boolean;
}

export interface INotificationItemFragment extends INotificationDetailsFragment {
  id: string;
  description: string;
  dismissed: boolean;
  imageUrl?: string;
  isNew: boolean;
}

interface Props extends WithStyles<typeof styles>, INotificationItemFragment {
  divider: boolean;
}
class NotificationItem extends React.Component<Props, {}> {
  public static defaultProps = {
    divider: true,
  };
  public state = {
    opened: false,
    deleted: false,
    snackbarOpened: false,
  };

  public dismissNotification = (
    mutate: ({ variables }: { variables: DismissNotificationMutationVariables }) => void,
    revert = false,
  ) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    mutate({
      variables: { notificationId: this.props.id, revert },
    });
  };

  public notificationDismissed = () => {
    this.setState({ deleted: true, snackbarOpened: true });
  };

  public notificationUndismissed = () => {
    this.setState({ deleted: false, snackbarOpened: false });
  };

  public handleSnackbarClose = () => {
    this.setState({ snackbarOpened: false });
  };

  public render() {
    const { classes, divider, subject, imageUrl, title, ...other } = this.props;
    return (
      <>
        {!this.state.deleted && (
          <Dialog open={this.state.opened} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
            <NotificationDetails subject={subject} title={title} {...other} />
          </Dialog>
        )}
        <Mutation mutation={DismissNotificationMutation} onCompleted={this.notificationUndismissed}>
          {(dismissNotification: ({ variables }: { variables: DismissNotificationMutationVariables }) => void) => (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={this.state.snackbarOpened}
              onClose={this.handleSnackbarClose}
              autoHideDuration={5000}
              message="Notification dismissed"
              action={[
                <Button
                  key="undo"
                  size="small"
                  className={classes.snackbarUndoButton}
                  onClick={this.dismissNotification(dismissNotification, true)}
                >
                  UNDO
                </Button>,
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  // className={classes.close}
                  onClick={this.handleSnackbarClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          )}
        </Mutation>
        {!this.state.deleted && (
          <ListItem button className={classes.root} onClick={this.openDialog}>
            <ListItemAvatar>
              <Avatar className={classes.avatar} alt={subject} src={imageUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={title}
              secondary={subject}
              primaryTypographyProps={{
                className: classes.primaryTypography,
              }}
              secondaryTypographyProps={{
                className: classes.secondaryTypography,
              }}
            />
            <Mutation mutation={DismissNotificationMutation} onCompleted={this.notificationDismissed}>
              {(dismissNotification: ({ variables }: { variables: DismissNotificationMutationVariables }) => void) => (
                <ListItemSecondaryAction className={classes.deleteButton}>
                  <IconButton aria-label="Delete" onClick={this.dismissNotification(dismissNotification)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </Mutation>
          </ListItem>
        )}
        {divider && <Divider className={classes.divider} />}
      </>
    );
  }

  private handleClose = () => {
    this.setState({
      opened: false,
    });
  };

  private openDialog = () => {
    this.setState({
      opened: true,
    });
  };
}

export default withStyles(styles)(NotificationItem);
