import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) => ({
  root: {
    gridAutoRows: '1fr',
    maxWidth: 1400,
    flexGrow: 1,
    marginTop: theme.spacing.unit * 8,
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      paddingBottom: '100px !important',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 800,
    },
  },
  margin: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing.unit * 5,
    },
    padding: theme.spacing.unit,
  },
  flexRoot: {
    [theme.breakpoints.up('lg')]: {
      maxHeight: 900,
      minHeight: 650,
      flexShrink: 1,
    },
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  flexContainer: {
    flexGrow: 1,
  },
  outer: {
    alignSelf: 'stretch',
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-around',
  },
});

interface Props extends WithStyles<typeof styles> {
  reverse: boolean;
  flex: boolean;
  margin: boolean;
}

class GridContainer extends React.Component<Props, {}> {
  public static defaultProps = {
    reverse: false,
    flex: false,
    margin: false,
  };
  public render() {
    const { classes, children, reverse, flex, margin } = this.props;
    return (
      <div className={classes.outer}>
        <div className={classnames(classes.root, flex && classes.flexRoot, margin && classes.margin)}>
          <Grid
            container
            direction={reverse ? 'row-reverse' : 'row'}
            spacing={40}
            className={classnames(flex && classes.flexContainer)}
          >
            {children}
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(GridContainer);
