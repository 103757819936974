import { gql } from 'apollo-boost';

const NavBarQuery = gql`
  query NavBarQuery {
    resident {
      id
      user {
        id
        username
      }
    }
  }
`;

export interface NavBarQueryData {
  resident: {
    user: {
      username: string;
    };
  };
}

export default NavBarQuery;
