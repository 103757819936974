import { gql } from 'apollo-boost';

import { BackgroundImageFragment, IBackgroundImageFragment } from '../components/BackgroundImage';
import { HomeValueChartFragment, IHomeValueChartFragment } from '../components/HomeValueChart';
import { HouseDetailsTextFragment, IHouseDetailsTextFragment } from '../components/HouseDetailsText';
import { IMonthlyPaymentChartFragment, MonthlyPaymentChartFragment } from '../components/MonthlyPaymentChart';
import { INotificationsFragment, NotificationsFragment } from '../components/Notifications';
import { IOwnershipAccrualChartFragment, OwnershipAccrualChartFragment } from '../components/OwnershipAccrualChart';

const MainPageQuery = gql`
  query MainPageQueryV2 {
    resident {
      id
      property {
        ...HomeValueChartFragment
        ...BackgroundImageFragment
      }
      ...MonthlyPaymentChartFragment
      ...NotificationsFragment
      ...HouseDetailsTextFragment
      savingsBuilder {
        ...OwnershipAccrualChartFragment
      }
    }
  }
  ${HomeValueChartFragment}
  ${HouseDetailsTextFragment}
  ${MonthlyPaymentChartFragment}
  ${NotificationsFragment}
  ${BackgroundImageFragment}
  ${OwnershipAccrualChartFragment}
`;
export interface MainPageQueryData {
  resident: IMonthlyPaymentChartFragment &
    INotificationsFragment &
    IHouseDetailsTextFragment & {
      property: IHomeValueChartFragment & IBackgroundImageFragment;
      savingsBuilder: IOwnershipAccrualChartFragment;
    };
}

export default MainPageQuery;
