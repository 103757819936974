import getenv from 'getenv';

const config = {
  backend: {
    url: getenv('REACT_APP_BACKEND_URL', `http://localhost:${getenv('REACT_APP_BACKEND_PORT', '8080')}`),
    apiPath: '/graphql',
    loginPath: '/login',
    logoutPath: '/api/logout',
    plaidPath: '/api/setup-autopay',
    payNowPath: '/api/pay-now',
  },
  plaid: {
    publicKey: getenv('REACT_APP_PLAID_PUBLIC_KEY'),
    environment: getenv('REACT_APP_PLAID_ENV'),
  },
  amplitude: {
    publicKey: getenv('REACT_APP_AMPLITUDE_API_KEY_KEY'),
  },
  paths: {
    autopay: '/setup-autopay',
    details: '/details',
    payments: '/payments',
    financials: '/financials',
    tutorial: '/hello',
    refer: '/accounts/refer',
  },
  stackdriver: {
    apiKey: getenv('REACT_APP_STACKDRIVER_API_KEY', ''),
    projectID: getenv('REACT_APP_PROJECT_ID', ''),
  },
  env: getenv('REACT_APP_ENV'),
};

export default config;
