import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import React from 'react';

import { gradients } from '../Theme';

const styles = (theme: Theme) => ({
  wrapper: {
    position: 'absolute' as 'absolute',
    width: '100%',
    zIndex: 100,
    bottom: 0,
    textAlign: 'center' as 'center',
  },
  dot: {
    display: 'inline-block',
    height: 8,
    width: 8,
    borderRadius: 4,
    background: `linear-gradient(90deg, ${gradients.secondary[0]} 0%, ${gradients.secondary[1]} 100%)`,
    margin: '7px 5px',
    transitionDuration: '300ms',
  },
});
interface Props extends WithStyles<typeof styles, true> {
  index: number;
  total: number;
}
class CarouselIndicatorDots extends React.Component<Props, {}> {
  public render() {
    const { classes, index, total } = this.props;
    return (
      <div className={classes.wrapper}>
        {Array.apply(null, Array(total)).map((x, i) => (
          <span className={classes.dot} key={i} style={index === i ? { opacity: 1 } : { opacity: 0.3 }} />
        ))}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CarouselIndicatorDots);
