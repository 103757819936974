import Grid, { GridProps } from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import * as React from 'react';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingTop: theme.spacing.unit * 0,
    padding: theme.spacing.unit * 2,
  },
  listItem: {
    height: theme.spacing.unit * 6,
    padding: theme.spacing.unit * 1,
    display: 'flex',
  },
  radialChart: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    borderRadius: 1000,
    width: '100%',
    paddingBottom: '100%',
  },
  square: {
    display: 'flex',
    borderRadius: 5,
    height: 200,
    marginBottom: theme.spacing.unit * 1,
  },
  largePadding: {
    paddingLeft: theme.spacing.unit * 5,
    paddingRight: theme.spacing.unit * 5,
  },
  chartContainer: {
    display: 'flex',
    maxWidth: 150,
    maxHeight: 150,
    padding: theme.spacing.unit * 2,
  },
  listAvatar: {
    borderRadius: 5,
    width: 56,
    height: 48,
    marginRight: theme.spacing.unit * 2,
  },
  text: {
    borderRadius: 5,
    margin: theme.spacing.unit * 2,
    height: theme.spacing.unit * 2,
    flexGrow: 1,
  },
  listText: {
    borderRadius: 5,
    height: theme.spacing.unit * 3,
    flexGrow: 1,
  },
  '@keyframes gradientAnimation': {
    '0%': {
      backgroundPosition: '0% 0%',
    },
    '100%': {
      backgroundPosition: '100% 0%',
    },
  },
  emptySpace: {
    [theme.breakpoints.up('lg')]: {
      height: theme.spacing.unit * 30,
    },
    height: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 3,
  },
  gradient: {
    backgroundSize: '800px 600px',
    background: 'linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%)',
    animation: `gradientAnimation infinite alternate ${2000}ms ease-in-out`,
  },
});

interface Props extends WithStyles<typeof styles> {
  loading: boolean;
  length?: number;
  type:
    | 'list'
    | 'radialChart'
    | 'linearChart'
    | 'text'
    | 'textAndRadialChart'
    | 'textAndLinearChart'
    | 'emptySpace'
    | 'tiles'
    | 'largeList';
  flexBasis?: number;
}

class LoadingIndicator extends React.Component<Props & GridProps, {}> {
  public renderTextPlaceholder() {
    const { length, classes } = this.props;
    return Array.from(Array(length || 3).keys()).map(n => (
      <div key={n} className={classnames(classes.text, classes.gradient)} />
    ));
  }
  public renderListPlaceholder() {
    const { length, classes } = this.props;
    return Array.from(Array(length || 3).keys()).map(n => (
      <div key={n} className={classes.listItem}>
        <div className={classnames(classes.listAvatar, classes.gradient)} />
        <div className={classnames(classes.listText, classes.gradient)} />
      </div>
    ));
  }

  public renderTilesPlaceholder() {
    const { length } = this.props;
    return Array.from(Array(length || 3).keys()).map(n => this.renderSquarePlaceholder(n));
  }

  public renderLargeListPlaceholder() {
    const { length } = this.props;
    return Array.from(Array(length || 3).keys()).map(n => (
      <Grid container key={n}>
        <Grid item xs={4}>
          {this.renderSquarePlaceholder()}
        </Grid>
        <Grid item xs={8}>
          {this.renderTextPlaceholder()}
        </Grid>
      </Grid>
    ));
  }
  public renderRadialChartPlaceholder() {
    const { classes } = this.props;
    return <div className={classnames(classes.radialChart, classes.gradient)} />;
  }
  public renderSquarePlaceholder(key?: number) {
    const { classes } = this.props;
    return <div key={key} className={classnames(classes.square, classes.gradient)} />;
  }

  public renderTextAndRadialChartPlaceholder() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={6}>
          {this.renderTextPlaceholder()}
        </Grid>
        <Grid item xs={6} className={classes.chartContainer}>
          {this.renderRadialChartPlaceholder()}
        </Grid>
      </Grid>
    );
  }

  public renderTextAndLinearChartPlaceholder() {
    const { classes } = this.props;
    return (
      <div className={classes.largePadding}>
        {this.renderTextPlaceholder()}
        {this.renderSquarePlaceholder()}
      </div>
    );
  }
  public renderPlaceholder() {
    const { type, classes } = this.props;
    switch (type) {
      case 'list':
        return this.renderListPlaceholder();
      case 'text':
        return this.renderTextPlaceholder();
      case 'textAndRadialChart':
        return this.renderTextAndRadialChartPlaceholder();
      case 'textAndLinearChart':
        return this.renderTextAndLinearChartPlaceholder();
      case 'emptySpace':
        return <div className={classes.emptySpace} />;
      case 'tiles':
        return this.renderTilesPlaceholder();
      case 'largeList':
        return this.renderLargeListPlaceholder();
      default:
        return false;
    }
  }
  public render() {
    const { classes, children, loading, flexBasis } = this.props;
    if (!loading) return children;
    return (
      <div className={classes.root} style={{ flexBasis }}>
        <>{this.renderPlaceholder()}</>
      </div>
    );
  }
}

export default withStyles(styles)(LoadingIndicator);
