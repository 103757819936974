import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { formatAsCurrency } from '../utils';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  equity: {
    marginTop: theme.spacing.unit * 3,
  },
  text: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
  },
  doughnut: {
    width: 80,
    height: 80,
    marginTop: -5,
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: 1000,
    margin: 7,
    marginLeft: 0,
    flexShrink: 0,
  },
  textLine: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const PaymentHistoryItemChartFragment = gql`
  fragment PaymentHistoryItemChartFragment on Payment {
    id
    parts {
      id
      title
      color
      amount
    }
  }
`;

export interface IPaymentHistoryItemChartFragment {
  id: number;
  parts: {
    id: string;
    title: string;
    color: string;
    amount: number;
  }[];
}
interface Props extends WithStyles<typeof styles>, IPaymentHistoryItemChartFragment {}

class PaymentHistoryItemChart extends React.Component<Props, {}> {
  public render() {
    const { classes, parts } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.text}>
          {parts &&
            parts.map(part => (
              <div className={classes.textLine} key={part.id}>
                <div className={classes.circle} style={{ backgroundColor: part.color }} />
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="body1">{formatAsCurrency(part.amount)}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">{part.title}</Typography>
                  </Grid>
                </Grid>
              </div>
            ))}
        </div>
        <div className={classes.doughnut}>
          <Doughnut
            options={{
              tooltips: { enabled: false },
              hover: { mode: undefined },
              cutoutPercentage: 70,
              maintainAspectRatio: false,
            }}
            data={{
              datasets: [
                {
                  data: parts && parts.map(part => part.amount),
                  backgroundColor: parts && parts.map(part => part.color),
                },
              ],
            }}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PaymentHistoryItemChart);
