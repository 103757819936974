import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Color from 'color';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import { gradients } from '../Theme';
import { formatAsMonthAndYear, formatAsPercentage } from '../utils';

const styles = (theme: Theme) => ({
  chart: {
    flexGrow: 1,
    flexBasis: 0,
  },
  chartContainer: {
    padding: '10px 40px 10px 40px',
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    flexGrow: 1,
  },
  chartContainerSmall: {
    padding: 0,
  },
  header: {
    marginBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  trend: {
    textAlign: 'center' as 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  trendArrow: {
    display: 'block',
    margin: 'auto',
    fontSize: '2em',
    fontWeight: 200,
  },
  value: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 36,
    },
  },
  valueDescription: {
    color: theme.palette.grey[500],
  },
});

export const OwnershipAccrualChartFragment = gql`
  fragment OwnershipAccrualChartFragment on SavingsBuilder {
    id
    ownershipAccrualChart {
      date
      value
      payoutPossible
    }
  }
`;

export interface IOwnershipAccrualChartFragment {
  ownershipAccrualChart: {
    date: string;
    value: number;
    payoutPossible: boolean;
  }[];
}
interface Props extends WithStyles<typeof styles, true>, IOwnershipAccrualChartFragment {
  small?: boolean;
}

class OwnershipAccrualChart extends React.Component<Props, {}> {
  public renderHeader() {
    const { classes, theme, small } = this.props;
    const currAccrual = this.getCurrentAccrual();

    return (
      <Grid item className={classes.header} style={{ marginBottom: small ? theme.spacing.unit * 2 : undefined }}>
        <Typography color="secondary" variant={small ? 'h2' : 'h1'} gutterBottom className={classes.value}>
          {formatAsPercentage(currAccrual.value)}
        </Typography>
        {!small && (
          <Typography color="secondary" variant="body1" gutterBottom className={classes.valueDescription}>
            Vested as of {formatAsMonthAndYear(new Date(currAccrual.date))}
          </Typography>
        )}
      </Grid>
    );
  }

  public renderChart() {
    const { classes, small, ownershipAccrualChart, theme } = this.props;
    const max = Math.max(...ownershipAccrualChart.map(({ value }) => value));
    const min = Math.min(...ownershipAccrualChart.map(({ value }) => value));
    const lastAccrualUnavailable = this.getLastAccrualUnavailable();
    return (
      <div className={classes.chart}>
        <Line
          data={this.getChartData}
          options={{
            hover: { mode: 'nearest' },
            maintainAspectRatio: small,
            elements: { point: { radius: 0, hitRadius: 10000 } },
            tooltips: {
              displayColors: false,
              backgroundColor: theme.palette.primary.main,
              callbacks: {
                label: (tooltipItem, chart) =>
                  Number(tooltipItem.yLabel) < lastAccrualUnavailable.value
                    ? `${formatAsPercentage(lastAccrualUnavailable.value)} after the cliff`
                    : formatAsPercentage(Number(tooltipItem.yLabel)),
                title: () => '',
              },
            },
            legend: { display: false },
            scales: {
              yAxes: [
                {
                  ticks: {
                    min: Math.max(0, min - 0.0 * (max - min)),
                    max: Math.ceil((max + 0.0 * (max - min)) / 5) * 5,
                    maxTicksLimit: 4,
                    callback: value => {
                      return `${value.toFixed(1)}%`;
                    },
                    fontColor: '#c7c7c7',
                    fontSize: 14,
                  },
                  gridLines: { color: 'rgba(0, 0, 0, 0.05)', zeroLineWidth: 0 },
                },
              ],
              xAxes: [
                {
                  gridLines: { display: false },
                  ticks: { fontColor: '#c7c7c7', fontSize: 14 },
                  type: 'time',
                  time: { unit: small ? 'year' : 'month' },
                },
              ],
            },
          }}
        />
      </div>
    );
  }
  public render() {
    const { classes, small } = this.props;
    return (
      <div className={small ? classes.chartContainerSmall : classes.chartContainer}>
        {this.renderHeader()}
        {this.renderChart()}
      </div>
    );
  }

  private getCurrentAccrual() {
    return (
      this.props.ownershipAccrualChart
        .slice()
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .reverse()
        .find(({ date }) => new Date(date).getTime() < new Date().getTime()) ||
      this.props.ownershipAccrualChart.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())[0]
    );
  }

  private getFirstAccrual() {
    return this.props.ownershipAccrualChart.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];
  }

  private getLastAccrualUnavailable() {
    return (
      this.props.ownershipAccrualChart
        .slice()
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .reverse()
        .find(({ payoutPossible }) => !payoutPossible) || this.props.ownershipAccrualChart[0]
    );
  }

  private getGradientOfOpacity(
    ctx: any,
    fade: number,
    color1 = gradients.secondary[1],
    color2 = gradients.secondary[0],
  ) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(
      0,
      Color(color1)
        .fade(fade)
        .toString(),
    );
    gradient.addColorStop(
      1,
      Color(color2)
        .fade(fade)
        .toString(),
    );
    return gradient;
  }
  private sortByDateAndMapToChart(list: { date: string; value: number }[]) {
    return list
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map(({ date, value }) => ({
        x: new Date(date),
        y: value,
      }));
  }
  private getChartData = (canvas: any) => {
    const { theme, ownershipAccrualChart } = this.props;
    const currAccrual = this.getCurrentAccrual();
    const firstAccrual = this.getFirstAccrual();
    const unpayoutableAccrual = this.getLastAccrualUnavailable();
    const ctx = canvas.getContext('2d');
    const historyGradient = this.getGradientOfOpacity(ctx, 0.6);
    const futureGradient = this.getGradientOfOpacity(ctx, 0.9);
    const historyGradientGrayout = this.getGradientOfOpacity(ctx, 0.3, '#fff', '#ccd');
    const data = {
      datasets: [
        {
          data: this.sortByDateAndMapToChart(
            ownershipAccrualChart.filter(({ date }) => new Date(date).getTime() < new Date().getTime()),
          ),
          fill: true,
          borderColor: theme.palette.secondary.main,
          backgroundColor: historyGradient,
          lineTension: 0.3,
          label: 'past',
        },
        {
          data: [{ x: new Date(currAccrual.date), y: currAccrual.value }],
          pointBackgroundColor: '#6FD0A1',
          pointBorderColor: '#6FD0A1',
          pointHoverBackgroundColor: '#6FD0A1',
          pointHoverBorderColor: '#6FD0A1',
          pointHoverRadius: 10,
          pointRadius: 10,
          pointHitRadius: 0,
          label: 'current',
        },
        {
          data: [
            { x: new Date(currAccrual.date), y: currAccrual.value },
            ...this.sortByDateAndMapToChart(
              ownershipAccrualChart.filter(({ date }) => new Date(date).getTime() > new Date().getTime()),
            ),
          ],
          fill: true,
          borderColor: theme.palette.secondary.main,
          backgroundColor: futureGradient,
          lineTension: 0.3,
          label: 'future',
        },
        {
          data: [
            {
              x: new Date(firstAccrual.date),
              y: 1000,
            },
            {
              x: new Date(unpayoutableAccrual.date),
              y: 1000,
            },
          ],
          fill: true,
          backgroundColor: historyGradientGrayout,
          label: 'past',
        },
      ],
    };
    return data;
  };
}

export default withStyles(styles, { withTheme: true })(OwnershipAccrualChart);
