import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import products from '../images/hello/products.png';
import wishesLg from '../images/hello/wishes/lg.svg';
import wishesMd from '../images/hello/wishes/md.svg';
import wishesSm from '../images/hello/wishes/sm.svg';

export interface ITutorialPage {
  name: string;
  title: string;
  content: (
    welcomeScreens: {
      name: string;
      imageUrl: string;
    }[]
  ) => React.ReactNode;
  redirectToPayment?: boolean;
  isAfterAutopay?: boolean;
}
export const tutorialPages: ITutorialPage[] = [
  {
    name: 'welcome',
    title: '',
    content: welcomeScreens => (
      <>
        <Hidden only={['xs', 'sm', 'md']}>
          <img src={wishesLg} style={{ width: '100%' }} />
        </Hidden>
        <Hidden only={['xs', 'lg', 'xl']}>
          <img src={wishesMd} style={{ width: '100%' }} />
        </Hidden>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <img src={wishesSm} style={{ width: '100%' }} />
        </Hidden>
      </>
    ),
  },
  {
    name: 'home',
    title: 'Your Home',
    content: () => (
      <>
        <Typography gutterBottom variant="body1">
          The home details dashboard has all of your home and neighborhood related data, in one place - accessible when
          you need it, where you need it.
        </Typography>
        <Typography gutterBottom variant="body1">
          You can also access all of your home related documents (disclosure statements, inspection reports) from this
          dashboard.
        </Typography>
        <Typography gutterBottom variant="body1">
          Your property tax and home insurance documents can also be accessed from this page.
        </Typography>
      </>
    ),
  },
  {
    name: 'concierge',
    title: 'Homeownership Concierge',
    redirectToPayment: true,
    content: () => (
      <>
        <Typography gutterBottom variant="body1">
          Concierge is a free service that connects you with high-quality, trusted repair and maintenance providers in
          your new neighborhood, all through your ZeroDown App.
        </Typography>
        <Typography gutterBottom variant="body1">
          Clogged sink? DIY project gone awry? We’ll lead the way to the best handymen and plumbers around.With our
          Homeownership Concierge, you get help with home-related services, all with the tap of a button.
        </Typography>
        <Typography gutterBottom variant="body1">
          Try asking the following questions to Concierge:
        </Typography>
        <Typography gutterBottom variant="body1">
          - “Help! My trash compactor isn’t working. I don’t know what to do.” <br />- “I was trying to install a
          bookshelf and accidentally drilled a hole through my wall.” <br />- “My dryer has given up on me. How do I get
          it working again?” <br />- “My heating system is making weird noises. Is something wrong with it?”
        </Typography>
      </>
    ),
  },
  // {
  //   name: 'rewards',
  //   title: 'ZeroDown Points',
  //   redirectToPayment: true,
  //   content: () => (
  //     <>
  //       <Typography gutterBottom variant="body1">
  //         As a ZeroDown homeowner, you are automatically enrolled in our membership rewards program which lets you earn
  //         points that are redeemable for a variety of goods and services in our app.
  //       </Typography>
  //       <Typography gutterBottom variant="body1">
  //         Earn reward points by making on-time monthly payments on your home. Connect your credit or debit card to
  //         supercharge your points and earn even more points for shopping with our rewards partners, including
  //         ride-sharing, grocery delivery, and more. You’ll earn 100,000 rewards points after you make your first payment
  //         to ZeroDown.
  //       </Typography>
  //       <Typography gutterBottom variant="overline">
  //         How do I redeem my Rewards points?
  //       </Typography>
  //       <Typography gutterBottom variant="body1">
  //         You can redeem your rewards points for a variety of goods and services available in the rewards tab including:
  //       </Typography>
  //       <div style={{ textAlign: 'center' }}>
  //         <img src={products} style={{ maxWidth: '100%', textAlign: 'center' }} />
  //       </div>
  //     </>
  //   ),
  // },
  {
    name: 'recap',
    title: "Thank you! You're all set.",
    isAfterAutopay: true,
    content: () => (
      <>
        <Typography gutterBottom variant="body1">
          Onwards and upwards! You’ve completed the set up of your ZeroDown home app. As a reminder:
        </Typography>
        <Typography gutterBottom variant="overline">
          Home Details Dashboard
        </Typography>
        <Typography gutterBottom variant="body1">
          Access all your home related details here
        </Typography>
        <Typography gutterBottom variant="overline">
          Homeownership Concierge
        </Typography>
        <Typography gutterBottom variant="body1">
          We are here to help, whenever you need!
        </Typography>
        <Typography gutterBottom variant="overline">
          Payments
        </Typography>
        <Typography gutterBottom variant="body1">
          You access your invoices and payment settings
        </Typography>
      </>
    ),
  },
];
