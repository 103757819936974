import List from '@material-ui/core/List';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { gql } from 'apollo-boost';
import * as React from 'react';

import PaymentHistoryItem, { IPaymentHistoryItemFragment, PaymentHistoryItemFragment } from './PaymentHistoryItem';

const styles = (theme: Theme) => ({
  root: {
    marginTop: -theme.spacing.unit * 2,
  },
  emptyText: {
    margin: theme.spacing.unit * 4,
  },
});

export const PaymentHistoryFragment = gql`
  fragment PaymentHistoryFragment on Resident {
    id
    paymentHistory {
      ...PaymentHistoryItemFragment
    }
  }
  ${PaymentHistoryItemFragment}
`;

export interface IPaymentHistoryFragment {
  paymentHistory: IPaymentHistoryItemFragment[];
}

interface Props extends WithStyles<typeof styles>, IPaymentHistoryFragment {}

class PaymentHistory extends React.Component<Props, {}> {
  public render() {
    const { classes, paymentHistory } = this.props;
    return (
      <List className={classes.root}>
        {paymentHistory.length ? (
          paymentHistory.map((item, idx) => (
            <PaymentHistoryItem {...item} divider={idx !== paymentHistory.length - 1} key={item.id} />
          ))
        ) : (
          <Typography className={classes.emptyText}>Your payment invoices will appear here</Typography>
        )}
      </List>
    );
  }
}

export default withStyles(styles)(PaymentHistory);
