import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps, withRouter } from 'react-router';
import LinkButton from '../components/LinkButton';
import MainGridContainer from '../components/MainGridContainer';
import withQueryAndBackground, { WithQuery } from '../components/PageQuery';
import RoundedPaper from '../components/RoundedPaper';
import config from '../config';
import TutorialPageQuery, { TutorialPageQueryData } from './TutorialPageQuery';
import { tutorialPages } from './tutorialPages';
import { ResidentStatus } from '../__generated__/graphql-global-types'

const ChangeResidentStatusMutation = gql`
  mutation changeResidentStatus($status: ResidentStatus!) {
    changeResidentStatus(status: $status) {
      resident {
        id
        status
        hasGivenAutopayConsent
      }
    }
  }
`;

const styles = (theme: Theme) => ({
  buttonContainer: {
    margin: 'auto',
    marginTop: theme.spacing.unit * 3,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
  },
  text: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  errorText: {
    marginBottom: theme.spacing.unit * 2,
  },
  progress: {
    display: 'block' as 'block',
    margin: 'auto',
    marginBottom: theme.spacing.unit * 3,
  },
  supportLink: {
    textDecoration: 'underline' as 'underline',
    cursor: 'pointer',
  },
  previousButton: {
    marginRight: theme.spacing.unit * 4,
  },
});

interface Props extends WithStyles<typeof styles>, WithQuery<TutorialPageQueryData> {
  page?: string;
}
class TutorialPage extends React.Component<Props & RouteComponentProps, {}> {
  public state = {};

  public render() {
    const { classes, page, data, loading } = this.props;
    const tutorialPage = tutorialPages.find(p => p.name === page) || tutorialPages[0];
    const nextTutorialPage = tutorialPages[tutorialPages.indexOf(tutorialPage) + 1];
    const previousTutorialPage = tutorialPages[tutorialPages.indexOf(tutorialPage) - 1];
    if (loading || !data) {
      return null;
    }

    return (
      <>
        <MainGridContainer margin flex>
          <Hidden smDown>
            <Grid item md={2} />
          </Hidden>
          <Grid item xs={12} md={8}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography gutterBottom variant="h6">
                Welcome to ZeroDown!
              </Typography>
            </div>
            <RoundedPaper
              title={tutorialPage.title}
              padding={true}
              shadow
              imageUrl={
                data.resident.welcomeScreens.filter(screen => screen.name === tutorialPage.name)[0]
                  ? data.resident.welcomeScreens.filter(screen => screen.name === tutorialPage.name)[0].imageUrl
                  : ''
              }
            >
              {tutorialPage.content(data.resident.welcomeScreens)}
              <div className={classes.buttonContainer}>
                {previousTutorialPage && (
                  <LinkButton
                    color="primary"
                    variant="contained"
                    to={previousTutorialPage.name}
                    className={classes.previousButton}
                  >
                    Back
                  </LinkButton>
                )}
                <Mutation mutation={ChangeResidentStatusMutation}>
                  {(changeResidentStatus: any) => (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        if (!nextTutorialPage) {
                          changeResidentStatus({
                            variables: {
                              status: ResidentStatus.MOVED_IN,
                            },
                          }).then(() => {
                            this.props.history.push('/');
                          });
                        } else {
                          const redirectUrl = tutorialPage.redirectToPayment
                            ? config.paths.autopay
                            : `${config.paths.tutorial}/${nextTutorialPage.name}`;
                          this.props.history.push(redirectUrl);
                        }
                      }}
                    >
                      {!nextTutorialPage ? "Done" : "Next"}
                    </Button>
                  )}
                </Mutation>
              </div>
            </RoundedPaper>
          </Grid>
          <Hidden smDown>
            <Grid item md={2} />
          </Hidden>
        </MainGridContainer>
      </>
    );
  }
}

export default withQueryAndBackground<TutorialPageQueryData>(
  TutorialPageQuery,
  withStyles(styles)(withRouter(TutorialPage))
);
