import List from '@material-ui/core/List';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { gql } from 'apollo-boost';
import * as React from 'react';

import NotificationItem, { INotificationItemFragment, NotificationItemFragment } from './NotificationItem';

const styles = (theme: Theme) => ({
  root: {
    marginTop: -theme.spacing.unit * 2,
  },
});

export const NotificationsFragment = gql`
  fragment NotificationsFragment on Resident {
    id
    notifications {
      ...NotificationItemFragment
    }
  }
  ${NotificationItemFragment}
`;

export interface INotificationsFragment {
  notifications: INotificationItemFragment[];
}

interface Props extends WithStyles<typeof styles>, INotificationsFragment {}

class Notifications extends React.Component<Props, {}> {
  public render() {
    const { classes, notifications } = this.props;
    return (
      <List className={classes.root}>
        {notifications.map((notification, idx) => (
          <NotificationItem {...notification} key={notification.id} divider={idx !== notifications.length - 1} />
        ))}
      </List>
    );
  }
}

export default withStyles(styles)(Notifications);
