import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';

import BackgroundImage from '../components/BackgroundImage';
import FlexGrid from '../components/FlexGrid';
import HouseDetails from '../components/HouseDetails';
import HouseDetailsText from '../components/HouseDetailsText';
import LoadingIndicator from '../components/LoadingIndicator';
import MainGridContainer from '../components/MainGridContainer';
import withQueryAndBackground, { WithQuery } from '../components/PageQuery';
import RoundedPaper from '../components/RoundedPaper';

import DetailsPageQuery, { DetailsPageQueryData } from './DetailsPageQuery';

const styles = (theme: Theme) => ({
  lowerCard: {
    [theme.breakpoints.up('lg')]: {
      marginTop: theme.spacing.unit * 22,
    },
  },
});

interface Props extends WithStyles<typeof styles>, WithQuery<DetailsPageQueryData> { }

class DetailsPage extends React.Component<Props, {}> {
  public render() {
    const { backgroundData, classes, data, error, loading } = this.props;
    return (
      <>
        <BackgroundImage {...backgroundData} />
        <MainGridContainer margin reverse>
          <FlexGrid item xs={12} lg={3}>
            <LoadingIndicator type="emptySpace" loading={loading}>
              {data && <HouseDetailsText {...data.resident} />}
            </LoadingIndicator>
          </FlexGrid>

          <FlexGrid item xs={12} lg={8}>
            <RoundedPaper shadow title="About Your House" className={classes.lowerCard}>
              <LoadingIndicator type="list" loading={loading}>
                {/* {data && <HouseDetails {...data.resident} />} */}
                {data && data.resident.property && (
                  <iframe
                  width="100%"
                  style={{ height: '900px', overflow: 'auto' }}
                  src={data.resident.property.home.homeUrl}
                ></iframe>)}

              </LoadingIndicator>
            </RoundedPaper>
          </FlexGrid>
          <FlexGrid item xs={12} lg={1} />
        </MainGridContainer>
      </>
    );
  }
}

export default withQueryAndBackground<DetailsPageQueryData>(DetailsPageQuery, withStyles(styles)(DetailsPage));
