import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Color from 'color';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { Line } from 'react-chartjs-2';

import { PropertyValue, PropertyValueFragment } from '../apollo/sharedFragments';
import { gradients } from '../Theme';
import { formatAsCurrency, formatAsMonthAndYear } from '../utils';

const styles = (theme: Theme) => ({
  chart: {
    flexGrow: 1,
    flexBasis: 0,
  },
  chartContainer: {
    padding: '10px 40px 10px 40px',
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    flexGrow: 1,
  },
  chartContainerSmall: {
    padding: 0,
  },
  header: {
    marginBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  trend: {
    textAlign: 'center' as 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  trendArrow: {
    display: 'block',
    margin: 'auto',
    fontSize: '2em',
    fontWeight: 200,
  },
  value: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 36,
    },
  },
  valueDescription: {
    color: theme.palette.grey[500],
  },
});

export const HomeValueChartFragment = gql`
  fragment HomeValueChartFragment on Property {
    id
    currentValue {
      ...PropertyValueFragment
    }
    values {
      ...PropertyValueFragment
    }
  }
  ${PropertyValueFragment}
`;

export interface IHomeValueChartFragment {
  id: number;
  currentValue: PropertyValue;
  values: PropertyValue[];
}

interface Props extends WithStyles<typeof styles, true>, IHomeValueChartFragment {
  small?: boolean;
}

class HomeValueChart extends React.Component<Props, {}> {
  public renderHeader() {
    const { classes, theme, small, currentValue } = this.props;
    return (
      <Grid item className={classes.header} style={{ marginBottom: small ? theme.spacing.unit * 2 : undefined }}>
        <Typography color="secondary" variant={small ? 'h2' : 'h1'} gutterBottom className={classes.value}>
          {formatAsCurrency(currentValue.value)}
        </Typography>
        {/* {!small && (
          <Typography color="secondary" variant="body1" gutterBottom className={classes.valueDescription}>
            Zillow estimate for home value as of {formatAsMonthAndYear(new Date(currentValue.startDate))}
          </Typography>
        )} */}
      </Grid>
    );
  }

  public renderChart() {
    const { classes, small, values, theme } = this.props;
    const max = Math.max(...values.map(v => v.value));
    const min = Math.min(...values.map(v => v.value));
    return (
      <div className={classes.chart}>
        <Line
          data={this.getChartData}
          options={{
            hover: { intersect: false },
            maintainAspectRatio: small,
            elements: {
              point: {
                radius: 0,
                hitRadius: 10000,
              },
            },
            tooltips: {
              displayColors: false,
              backgroundColor: theme.palette.primary.main,
              callbacks: {
                label: (tooltipItem, chart) => formatAsCurrency(Number(tooltipItem.yLabel)),
                title: (tooltipItem, chart) => '',
              },
            },
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    suggestedMin: Math.max(0, min - 0.3 * (max - min)),
                    suggestedMax: max + 0.3 * (max - min),
                    maxTicksLimit: 8,
                    callback: value => {
                      value = parseInt(value) / 1000000;
                      return `${value}m`;
                    },
                    fontColor: '#c7c7c7',
                    fontSize: 14,
                  },
                  gridLines: {
                    color: 'rgba(0, 0, 0, 0.05)',
                    zeroLineWidth: 0,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    fontColor: '#c7c7c7',
                    fontSize: 14,
                  },
                  type: 'time',
                  time: {
                    unit: 'month',
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }

  public render() {
    const { classes, small } = this.props;
    return (
      <div className={small ? classes.chartContainerSmall : classes.chartContainer}>
        {this.renderHeader()}
        {this.renderChart()}
      </div>
    );
  }

  private getGradientOfOpacity(ctx: any, fade: number) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(
      0,
      Color(gradients.secondary[1])
        .fade(fade)
        .toString(),
    );
    gradient.addColorStop(
      1,
      Color(gradients.secondary[0])
        .fade(fade)
        .toString(),
    );
    return gradient;
  }

  private getChartData = (canvas: any) => {
    const { theme, values, currentValue } = this.props;
    const ctx = canvas.getContext('2d');
    const historyGradient = this.getGradientOfOpacity(ctx, 0.6);
    const futureGradient = this.getGradientOfOpacity(ctx, 0.9);
    return {
      datasets: [
        {
          data: [
            ...values
              .filter(({ startDate }) => new Date(startDate).getTime() - new Date().getTime() < 0)
              .map(value => ({
                x: new Date(value.startDate),
                y: value.value,
              })),
            {
              x: new Date(currentValue.startDate),
              y: currentValue.value,
            },
          ],
          fill: true,
          borderColor: theme.palette.secondary.main,
          backgroundColor: historyGradient,
          lineTension: 0.3,
          label: 'past',
        },
        {
          data: [
            {
              x: new Date(currentValue.startDate),
              y: currentValue.value,
            },
          ],
          pointBackgroundColor: '#6FD0A1',
          pointBorderColor: '#6FD0A1',
          pointHoverBackgroundColor: '#6FD0A1',
          pointHoverBorderColor: '#6FD0A1',
          pointHoverRadius: 10,
          pointRadius: 10,
          pointHitRadius: 0,
          label: 'current',
        },
        {
          data: [
            {
              x: new Date(currentValue.startDate),
              y: currentValue.value,
            },
            ...values
              .filter(({ startDate }) => new Date(startDate).getTime() - new Date().getTime() > 0)
              .map(value => ({
                x: new Date(value.startDate),
                y: value.value,
              })),
          ],
          fill: true,
          borderColor: theme.palette.secondary.main,
          backgroundColor: futureGradient,
          lineTension: 0.3,
          label: 'future',
        },
      ],
    };
  };
}

export default withStyles(styles, { withTheme: true })(HomeValueChart);
