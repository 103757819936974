import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import PlaidLink from 'react-plaid-link';
import moment from 'moment';
import FlexGrid from '../components/FlexGrid';
import LoadingIndicator from '../components/LoadingIndicator';
import MainGridContainer from '../components/MainGridContainer';
import MonthlyPaymentBreakdown from '../components/MonthlyPaymentBreakdown';
import withQueryAndBackground, { WithQuery } from '../components/PageQuery';
import PaymentDate from '../components/PaymentDate';
import PaymentMethodList from '../components/PaymentMethodList';
import PaymentPrompt from '../components/PaymentPrompt';
import RoundedPaper from '../components/RoundedPaper';
import config from '../config';
import { tutorialPages } from '../TutorialPage/tutorialPages';
import AutopayPageQuery, { AutopayPageQueryData } from './AutopayPageQuery';

export const DB_DATE_FORMAT = 'YYYY-MM-DD';

const styles = (theme: Theme) => ({
  autopay: {
    overflow: 'scroll',
  },
  history: {
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  payment: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  spacer: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  smallSpacer: {
    height: theme.spacing.unit * 2,
  },
  button: {
    display: 'block' as 'block',
    margin: 'auto',
    textTransform: 'uppercase' as 'uppercase',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 700,
    marginBottom: theme.spacing.unit * 3,
  },
  text: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  errorText: {
    marginBottom: theme.spacing.unit * 2,
  },
  progress: {
    display: 'block' as 'block',
    margin: 'auto',
    marginBottom: theme.spacing.unit * 3,
  },
  supportLink: {
    textDecoration: 'underline' as 'underline',
    cursor: 'pointer',
  },
});

interface Props extends WithStyles<typeof styles, true>, WithQuery<AutopayPageQueryData> {}

function reportError(message?: any, ...optionalParams: any[]) {
  // Stackdriver reporting
  if (['staging', 'prod'].includes(config.env) && window.errorHandler) {
    window.errorHandler.report(`${message} - context: ${JSON.stringify(optionalParams)}`);
  }
  // Report it to console anyway.
  console.error(message, ...optionalParams);
}

class AutopayPage extends React.Component<Props, {}> {
  public state = {
    processing: false,
    error: false,
    success: false,
  };
  public handleOnSuccess = (publicToken: string, metadata: any) => {
    const { data: queryData } = this.props;
    const data = {
      public_token: publicToken,
      account_id: metadata.account_id,
    };
    this.setState({
      processing: true,
      error: false,
    });
    fetch(`${config.backend.url}${config.backend.plaidPath}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            processing: false,
            success: true,
          });
          setTimeout(
            () =>
              (window.location.href =
                queryData && queryData.resident.autopayConfigured
                  ? '/'
                  : `${config.paths.tutorial}/${
                      (
                        tutorialPages.find(p => !!p.isAfterAutopay) || {
                          name: '',
                        }
                      ).name
                    }`),
            1000
          );
        } else {
          this.setState({
            processing: false,
            error: true,
          });
        }
      })
      .catch(err => {
        this.setState({
          processing: false,
          error: true,
        });
      });
  };

  public handleOnExit = (err: any, metadata: any) => {
    if (err) {
      const user = this.props.data && this.props.data.user;
      // for stackdrive logs.
      reportError('Plaid exited with error in the auto pay setup!', {
        error: err,
        metadata,
        user: user && {
          username: user.username,
          userId: user.userId,
          fullName: `${user.firstName} ${user.lastName}`,
        },
      });
    }

    if (err != null) {
      this.setState({
        error: true,
      });
    }
  };

  public renderButton = () => {
    const { classes, theme, data } = this.props;
    return (
      <PlaidLink
        clientName="Zerodown"
        env={config.plaid.environment}
        token={data && data.resident && data.resident.plaidLinkToken}
        product={['auth']}
        apiVersion="v2"
        selectAccount={true}
        onExit={this.handleOnExit}
        onSuccess={this.handleOnSuccess}
        // It's not pretty and should be improved after resolving https://github.com/pbernasconi/react-plaid-link/issues/26
        className={classes.button}
        style={{
          ...(theme.overrides as any).MuiButton.root,
          ...(theme.overrides as any).MuiButton.contained,
          ...(theme.overrides as any).MuiButton.containedPrimary,
        }}
      >
        Add auto-pay account
      </PlaidLink>
    );
  };

  public renderSpinner = () => {
    const { classes } = this.props;
    return <CircularProgress className={classes.progress} />;
  };

  public renderError = () => {
    const { classes } = this.props;
    return (
      <Typography className={classes.errorText}>An error has occurred. Try again or contact our support .</Typography>
    );
  };

  public renderSuccess = () => <Typography>Success! You'll be redirected shortly.</Typography>;

  public render() {
    const { classes, backgroundData, loading, data } = this.props;
    const { processing, error, success } = this.state;
    var today = new Date();
    return (
      <>
        <MainGridContainer margin>
          <Hidden smDown>
            <Grid item md={2} />
          </Hidden>
          <Grid item xs={12} md={8} lg={12}>
            <RoundedPaper
              title={
                data && data.resident.autopayConfigured && data.resident.hasGivenAutopayConsent
                  ? 'Add a new auto-pay bank account'
                  : 'Confirm auto-pay bank account'
              }
              shadow
              padding
            >
              <LoadingIndicator type="text" loading={loading}>
                <Typography className={classes.text}>
                  Fast, easy and secure. Setting up auto-pay let's you enjoy all of the benefits and conveniences of the
                  ZeroDown app. Simply enter your bank account details, and we’ll collect your monthly payment without
                  any hassle to you.
                </Typography>
                <FlexGrid item xs={12} md={12} lg={12}>
                  <RoundedPaper padding shadow marginBottom={20}>
                    <LoadingIndicator type="text" loading={loading} length={2}>
                      {data && <PaymentPrompt {...data.resident} />}
                    </LoadingIndicator>
                  </RoundedPaper>
                  <RoundedPaper shadow grow padding title="Monthly Payment Breakdown">
                    <LoadingIndicator type="textAndLinearChart" loading={loading}>
                      {data && <MonthlyPaymentBreakdown {...data.resident} />}
                    </LoadingIndicator>
                  </RoundedPaper>
                  <RoundedPaper padding shadow title="Payment settings">
                    <LoadingIndicator type="text" loading={loading} length={2}>
                      <Typography variant="caption">Payment Day</Typography>
                      {data && <PaymentDate {...data.resident} />}
                      <div className={classes.smallSpacer} />
                      {data && data.resident.autopayConfigured ? (
                        <>
                          <Typography variant="caption">Bank Accounts</Typography>
                        </>
                      ) : (
                        <>
                          <Typography>No bank accounts added yet!</Typography>
                        </>
                      )}
                      {data && <PaymentMethodList {...data.resident} />}
                    </LoadingIndicator>
                  </RoundedPaper>
                </FlexGrid>
                {data && !data.resident.hasGivenAutopayConsent && !data.resident.autopayConfigured ? (
                  <>
                    <Typography className={classes.text}>
                      By confirming the above auto-pay account, you authorize T. P. Hamilton, Inc. d/b/a ZeroDown
                      (“ZeroDown”), its affiliates (including TCFI Home Holdings LLC) and entities directly or
                      indirectly managed by it, and each of their successors and assigns, as of{' '}
                      {moment(today, DB_DATE_FORMAT).format('MMMM DD, YYYY')} to debit such account for your monthly
                      payments as provided for in your lease agreement with us, and to credit or debit such account to
                      correct any mistaken debits or credits. To revoke this authorization, just delete the account on
                      the Payments page or this page afterwards.
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography className={classes.text}>
                      By adding a new auto-pay account, you authorize T. P. Hamilton, Inc. d/b/a ZeroDown (“ZeroDown”),
                      its affiliates (including TCFI Home Holdings LLC) and entities directly or indirectly managed by
                      it, and each of their successors and assigns, as of{' '}
                      {moment(today, DB_DATE_FORMAT).format('MMMM DD, YYYY')} to debit such account for your monthly
                      payments as provided for in your lease agreement with us, and to credit or debit such account to
                      correct any mistaken debits or credits. To revoke this authorization, just delete the account on
                      the Payments page or this page afterwards.
                    </Typography>
                  </>
                )}
                {success ? (
                  this.renderSuccess()
                ) : (
                  <>
                    {error && this.renderError()}
                    {processing ? this.renderSpinner() : this.renderButton()}
                  </>
                )}
              </LoadingIndicator>
            </RoundedPaper>
          </Grid>
          <Hidden smDown>
            <Grid item md={2} />
          </Hidden>
        </MainGridContainer>
      </>
    );
  }
}

export default withQueryAndBackground(AutopayPageQuery, withStyles(styles, { withTheme: true })(AutopayPage));
