import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { IBaseResidentFragment } from './PageQuery';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    height: 500,
    backgroundSize: 'cover',
    background: 'linear-gradient(270deg, rgba(87, 0, 255, 0.82) -5%, #39AC8B 105%)',
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
  },
  scrim: {
    width: '100%',
    height: '100%',
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    background: 'linear-gradient(280deg, rgba(53, 49, 62, 0.52) 10%, rgba(53, 49, 62, 0) 50%)',
  },
});

export const BackgroundImageFragment = gql`
  fragment BackgroundImageFragment on Property {
    imageUrl
  }
`;

export interface IBackgroundImageFragment {
  imageUrl?: string;
}

interface Props extends WithStyles<typeof styles>, IBaseResidentFragment {}

class BackgroundImage extends React.Component<Props, {}> {
  public render() {
    const { classes, data, error, loading } = this.props;
    let imageUrl = null;
    if (!loading && !error && data) {
      imageUrl = data.resident.property.imageUrl;
    }
    return (
      <div className={classes.root} style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}>
        <div className={classes.scrim} />
      </div>
    );
  }
}

export default withStyles(styles)(BackgroundImage);
