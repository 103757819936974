import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import classnames from 'classnames';
import Color from 'color';
import * as React from 'react';
import { Link } from 'react-router-dom';

import logo from '../images/logo-icon-light.svg';
import config from '../config';
import { fonts } from '../Theme';

import { default as NavBarQuery, NavBarQueryData } from './NavBarQuery';
import withQueryAndBackground, { WithQuery } from './PageQuery';

const styles = (theme: Theme) => ({
  additionalText: {
    whiteSpace: 'pre-line' as 'pre-line',
    color: 'white',
    paddingLeft: 16,
    paddingRight: 10,
    ['&:focus']: {
      outline: 'none',
    },
  },
  grow: {
    flexGrow: 1,
    flexBasis: 0,
  },
  profileButtons: {
    justifyContent: 'flex-end' as 'flex-end',
    display: 'flex' as 'flex',
  },
  menu: {
    backgroundColor: Color(theme.palette.primary.main)
      .fade(0.3)
      .toString(),
  },
  menuLink: {
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  profileIcon: {
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuButton: {
    color: '#FFFFFF',
    fontFamily: fonts.primary,
    fontSize: '12pt',
    textTransform: 'none' as 'none',
    fontWeight: 'normal' as 'normal',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    padding: 5,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  menuIcon: {
    color: 'white',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    color: '#FFFFFF',
    fontSize: '19pt',
    letterSpacing: '1px',
  },
  titleLink: {
    textDecoration: 'none',
  },
  root: {
    background: Color(theme.palette.primary.main)
      .fade(0.3)
      .toString(),
    height: 80,
  },
  toolbar: {
    height: 80,
  },
});

interface Props extends WithStyles<typeof styles>, WithQuery<NavBarQueryData> {
  onlySupport: boolean;
}

class NavBar extends React.Component<Props, {}> {
  public static defaultProps = {
    onlySupport: false,
  };

  public state = {
    auth: true,
    anchorEl: null,
    profileAnchorEl: null,
  };

  public menuLinks = [
    // {
    //   path: config.paths.details,
    //   label: 'Details',
    //   additionalText: undefined, // ts trick
    // },
    { path: config.paths.payments, label: 'Payments' },
    { path: config.paths.financials, label: 'Financials' },
  ];

  public menuProfileLinks = [
    {
      onClick: () => {
        window.location.href = `${config.backend.url}${config.backend.logoutPath}`;
      },
      path: 'logout',
      label: 'Logout',
    },
  ];

  public handleMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  public handleProfileMenu = (event: any) => {
    this.setState({ profileAnchorEl: event.currentTarget });
  };

  public handleClose = () => {
    this.setState({ anchorEl: null, profileAnchorEl: null });
  };

  public renderButton = (label: string, path?: string, onClick?: any) =>
    onClick ? (
      <Button
        key={path}
        className={classnames(this.props.classes.menuButton, this.props.classes.menuLink)}
        onClick={onClick!}
      >
        {label}
      </Button>
    ) : (
      path && (
        <Link key={path} to={path} className={this.props.classes.menuLink}>
          <Button className={this.props.classes.menuButton}>{label}</Button>
        </Link>
      )
    );

  public renderMobileButton = (label: string, path?: string, onClick?: () => void, additionalText?: string) => {
    const menuLink = [
      onClick ? (
        <MenuItem
          onClick={() => {
            onClick();
            this.handleClose();
          }}
          className={this.props.classes.menuButton}
        >
          {label}
        </MenuItem>
      ) : (
        path && (
          <MenuItem
            onClick={this.handleClose}
            to={path}
            // @ts-ignore
            component={Link}
            className={this.props.classes.menuButton}
          >
            {label}
          </MenuItem>
        )
      ),
    ];

    if (additionalText) {
      menuLink.push(
        <Typography variant="body2" className={this.props.classes.additionalText}>
          {additionalText}
        </Typography>
      );
    }
    return menuLink;
  };

  public renderButtons = () => {
    const { onlySupport } = this.props;
    return this.menuLinks
      .filter(l => !onlySupport || l.label === 'Support')
      .map(link => this.renderButton(link.label, link.path));
  };

  public renderMobileButtons = () => {
    const { onlySupport } = this.props;
    return this.menuLinks
      .filter(l => !onlySupport || l.label === 'Support')
      .map(link => this.renderMobileButton(link.label, link.path));
  };

  public renderUsername = () => {
    const { loading, data } = this.props;
    if (!loading && data && data.resident && data.resident.user) {
      const username = data.resident.user.username;
      return (
        <Typography variant="body2" className={this.props.classes.additionalText}>
          Hello, {username}!
        </Typography>
      );
    }
    return null;
  };

  public renderProfileButtons = () => {
    const { onlySupport } = this.props;
    return this.menuProfileLinks
      .filter(l => !onlySupport || l.label === 'Support')
      .map(link => this.renderMobileButton(link.label, link.path, link.onClick));
  };

  public render() {
    const { classes } = this.props;
    const { anchorEl, profileAnchorEl } = this.state;
    const open = Boolean(anchorEl);
    const profileOpen = Boolean(profileAnchorEl);

    return (
      <AppBar elevation={0} position="fixed" color="inherit" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.grow}>{this.renderButtons()}</div>
          <Link to="/" className={classes.titleLink}>
            <img src={logo} />
          </Link>
          <div className={classnames(classes.grow, classes.profileButtons)}>
            <Button
              className={classnames(this.props.classes.menuButton, this.props.classes.menuLink)}
              aria-label="Profile"
              aria-owns={profileOpen ? 'profile-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleProfileMenu}
            >
              Account
            </Button>

            <Menu
              id="profile-appbar"
              MenuListProps={{ className: classes.menu }}
              anchorEl={profileAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={profileOpen}
              onClose={this.handleClose}
            >
              {this.renderUsername()}
              {this.renderProfileButtons()}
            </Menu>

            <IconButton
              className={classes.menuIcon}
              color="inherit"
              aria-label="Menu"
              aria-owns={open ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
            <Menu
              id="menu-appbar"
              MenuListProps={{ className: classes.menu }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={this.handleClose}
            >
              {this.renderMobileButtons()}
              <Divider />
              {this.renderUsername()}
              {this.renderProfileButtons()}
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withQueryAndBackground<NavBarQueryData>(NavBarQuery, withStyles(styles)(NavBar), false);
