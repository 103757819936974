import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { formatAsCurrency } from '../utils';

const styles = (theme: Theme) => ({
  doughnut: {},
  amount: {
    display: 'inline-block' as 'inline-block',
    minWidth: 60,
  },
  dot: {
    display: 'inline-block' as 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: theme.spacing.unit,
  },
  type: {
    display: 'inline-block' as 'inline-block',
  },
  sum: {
    color: theme.palette.secondary.main,
    fontSize: 24,
    marginBottom: theme.spacing.unit,
  },
});

export const MonthlyPaymentBreakdownFragment = gql`
  fragment MonthlyPaymentBreakdownFragment on Resident {
    id
    invoiceLineItems {
      id
      amount
      color
      type
    }
  }
`;

export interface IMonthlyPaymentBreakdownFragment {
  invoiceLineItems: {
    id: string;
    amount: number;
    color: string;
    type: string;
  }[];
}

interface Props extends WithStyles<typeof styles, true>, IMonthlyPaymentBreakdownFragment {}

class MonthlyPaymentBreakdown extends React.Component<Props, {}> {
  public render() {
    const { classes, invoiceLineItems, theme } = this.props;

    const sum = invoiceLineItems.map(line => line.amount).reduce((acc, curr) => acc + curr, 0);

    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography className={classes.sum}>{formatAsCurrency(sum)}</Typography>
          {invoiceLineItems.map(line => (
            <div key={line.id}>
              <div style={{ backgroundColor: line.color }} className={classes.dot} />
              <Typography inline className={classes.amount}>
                {formatAsCurrency(line.amount)}
              </Typography>
              <Typography inline className={classes.type}>
                {line.type}
              </Typography>
            </div>
          ))}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(MonthlyPaymentBreakdown);
