import { gql } from 'apollo-boost';

import {
  CurrentHomeValueTableFragment,
  ICurrentHomeValueTableFragment,
} from "../components/CurrentHomeValueTable";
import {
  HomeValueChartFragment,
  IHomeValueChartFragment,
} from "../components/HomeValueChart";
import {
  HouseDetailsTextFragment,
  IHouseDetailsTextFragment,
} from "../components/HouseDetailsText";
import {
  IMonthlyPaymentBreakdownFragment,
  MonthlyPaymentBreakdownFragment,
} from "../components/MonthlyPaymentBreakdown";
import {
  IOwnershipAccrualChartFragment,
  OwnershipAccrualChartFragment,
} from "../components/OwnershipAccrualChart";
import {
  IRightToPurchaseChartFragment,
  RightToPurchaseChartFragment,
} from "../components/RightToPurchaseChart";

import {
  FinancialDocumentsFragment,
  IFinancialDocumentsFragment,
} from "./FinancialDocuments";

const FinancialsPageQuery = gql`
  query SavingBuilderPageQuery {
    resident {
      id
      property {
        ...HomeValueChartFragment
        home {
          ...CurrentHomeValueTableFragment
        }
      }
      ...HouseDetailsTextFragment
      ...FinancialDocumentsFragment
      ...MonthlyPaymentBreakdownFragment
      savingsBuilder {
        ...OwnershipAccrualChartFragment
        ...RightToPurchaseChartFragment
      }
    }
  }
  ${HomeValueChartFragment}
  ${HouseDetailsTextFragment}
  ${FinancialDocumentsFragment}
  ${OwnershipAccrualChartFragment}
  ${RightToPurchaseChartFragment}
  ${MonthlyPaymentBreakdownFragment}
  ${CurrentHomeValueTableFragment}
`;
export interface FinancialsPageQueryData {
  resident: IHouseDetailsTextFragment &
    IHouseDetailsTextFragment &
    IFinancialDocumentsFragment &
    IMonthlyPaymentBreakdownFragment & {
      property: IHomeValueChartFragment & {
        home: ICurrentHomeValueTableFragment;
      };
      savingsBuilder: IOwnershipAccrualChartFragment &
        IRightToPurchaseChartFragment;
    };
}

export default FinancialsPageQuery;
