import { gql } from 'apollo-boost';
import { ApolloError } from 'apollo-client';
import { DocumentNode } from 'graphql';
import * as React from 'react';
import { Query } from 'react-apollo';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { AutopayFragment } from '../AutopayPage/AutopayPageQuery';
import config from '../config';
import { ResidentStatus } from '../__generated__/graphql-global-types';
import ErrorPage from '../ErrorPage/ErrorPage';
import { tutorialPages } from '../TutorialPage/tutorialPages';
import { BackgroundImageFragment, IBackgroundImageFragment } from './BackgroundImage';

export const BaseResidentQuery = gql`
  query BaseResidentQuery {
    resident {
      id
      status
      ...AutopayFragment
      property {
        id
        ...BackgroundImageFragment
      }
    }
  }
  ${AutopayFragment}
  ${BackgroundImageFragment}
`;

export type IBaseResidentFragment = {
  data?: {
    resident: {
      status: string;
      autopayConfigured: boolean;
      property: IBackgroundImageFragment;
    };
  };
  loading: boolean;
  error?: ApolloError;
};

export type IBackgroundData = {
  backgroundData: IBaseResidentFragment;
};

export type WithQuery<DataType> = {
  data?: DataType;
  loading: boolean;
  error?: ApolloError;
} & IBackgroundData;

type WrapperProps<DataType> = {
  query: DocumentNode;
  WrappedComponent: React.ComponentType<WithQuery<DataType>>;
};

class PageQueryWrapper<DataType> extends React.Component<
  WrapperProps<DataType> & RouteComponentProps & { displaysErrorPage: boolean }
> {
  public render() {
    const { query, WrappedComponent, displaysErrorPage, ...other } = this.props;
    return (
      <Query query={BaseResidentQuery}>
        {(baseData: any) => (
          <Query query={query}>
            {({ loading, error, data }: { data?: DataType; loading: boolean; error?: ApolloError }) => {
              if (error) {
                return <ErrorPage error={error} redirectsOnly={!displaysErrorPage} />;
              }

              if (!baseData.loading && baseData.data && !baseData.data.resident) {
                window.location.href = `${config.backend.url}/login`;
                return null;
              }

              if (
                !baseData.loading &&
                baseData.data &&
                baseData.data.resident &&
                (baseData.data.resident.status as ResidentStatus) !== ResidentStatus.MOVED_IN &&
                this.props.location.pathname !== config.paths.autopay &&
                !this.props.location.pathname.includes(config.paths.tutorial)
              ) {
                return <Redirect to={`${config.paths.tutorial}/${tutorialPages[0].name}`} />;
              }

              if (
                !baseData.loading &&
                baseData.data &&
                baseData.data.resident &&
                !baseData.data.resident.hasGivenAutopayConsent &&
                (baseData.data.resident.status as ResidentStatus) === ResidentStatus.MOVED_IN &&
                this.props.location.pathname !== config.paths.autopay
              ) {
                return <Redirect to={`${config.paths.autopay}`} />;
              }

              return (
                <WrappedComponent
                  loading={loading}
                  data={loading ? undefined : data}
                  error={error}
                  backgroundData={baseData}
                  {...other}
                />
              );
            }}
          </Query>
        )}
      </Query>
    );
  }
}

function withQueryAndBackground<DataType>(
  query: DocumentNode,
  WrappedComponent: React.ComponentType<WithQuery<DataType>>,
  displaysErrorPage = true
) {
  const PageQueryWrapperWithRouter = withRouter(PageQueryWrapper);

  return (props: any) => (
    <PageQueryWrapperWithRouter
      query={query}
      WrappedComponent={WrappedComponent}
      displaysErrorPage={displaysErrorPage}
      {...props}
    />
  );
}

export default withQueryAndBackground;
