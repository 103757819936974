import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import StoreIcon from '@material-ui/icons/Store';
import { gql } from 'apollo-boost';
import * as React from 'react';

import { fonts } from '../Theme';
import { formatAsCurrency, formatAsPercentage } from '../utils';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: theme.spacing.unit * 10,
    minHeight: theme.spacing.unit * 20,
    marginBottom: theme.spacing.unit * 3,
    color: 'white',
    textAlign: 'right' as 'right',
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing.unit,
      marginTop: 0,
    },
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    whiteSpace: 'nowrap' as 'nowrap',
  },
  address: {
    marginTop: '0.5em',
    marginBottom: '0.1em',
    fontSize: 24,
    fontFamily: fonts.secondary,
    fontWeight: 700,
  },
  city_zip: {
    marginTop: '0.1em',
    marginBottom: '0.5em',
    fontSize: 15,
    fontFamily: fonts.secondary,
    fontWeight: 400,
  },
  payment: {
    marginTop: '0.5em',
    marginBottom: '0.5em',
    fontSize: 18,
    fontFamily: fonts.primary,
  },
  vestedPercentage: {
    marginTop: '0.5em',
    marginBottom: '0.5em',
    fontSize: 14,
    fontFamily: fonts.primary,
    color: '#39AC8B',
  },
  storeIcon: {
    position: 'relative' as 'relative',
    top: 2,
    right: 3,
  },
});

export const HouseDetailsTextFragment = gql`
  fragment HouseDetailsTextFragment on Resident {
    id
    property {
      id
      home {
        homeUrl
        id
        street
        city
        state
        zipcode
      }
    }
    savingsBuilder {
      id
      currentVestPercentage
    }
    monthlyPaymentAmount
  }
`;

export interface IHouseDetailsTextFragment {
  property: {
    id: number;
    home: {
      homeUrl: string;
      street: string;
      city: string;
      state: string;
      zipcode: string;
    };
  };
  savingsBuilder: {
    currentVestPercentage: number;
  };
  monthlyPaymentAmount?: number;
}
interface Props extends WithStyles<typeof styles>, IHouseDetailsTextFragment { }

class HouseDetailsText extends React.Component<Props, {}> {
  public render() {
    const {
      classes,
      property: {
        home: { street, city, state, zipcode },
      },
      savingsBuilder: { currentVestPercentage },
      monthlyPaymentAmount,
    } = this.props;
    return (
      <div className={classes.root}>
        <p className={classes.address}>{street}</p>
        <p className={classes.city_zip}>
          {city}, {state}, {zipcode}
        </p>
        <p className={classes.payment}>Monthly payment: {formatAsCurrency(monthlyPaymentAmount)}</p>
        <p className={classes.vestedPercentage}>
          <StoreIcon fontSize="inherit" className={classes.storeIcon} />
          {formatAsPercentage(currentVestPercentage)} vested
        </p>
      </div>
    );
  }
}

export default withStyles(styles)(HouseDetailsText);
