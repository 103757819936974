import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import {
  Select,
  OutlinedInput,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import config from '../config';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 2,
    },
  },
  coverPhoto: {
    height: 200,
    margin: -theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      margin: -theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  buttonWrapper: {
    textAlign: 'center' as 'center',
    marginTop: theme.spacing.unit * 2,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
  smallSpacer: {
    height: theme.spacing.unit * 2,
  },
});

export interface IPayNowFragment {
  onClose: () => void;
  monthlyPaymentAmount?: number;
  nextPaymentMonth?: string;
  nextPaymentMonthOptions?: [string];
  paymentMethods: {
    id: string;
    last4: string;
    routingNumber: string;
    bankName: string;
    isDefault: boolean;
  }[];
}

interface Props extends WithStyles<typeof styles>, IPayNowFragment {}

class PayNow extends React.Component<Props, {}> {
  public state = {
    step: 'details',
    processing: false,
    numMonths: 1,
    totalAmount: 0,
    monthlyPayment: 0,
  };

  public handleConfirmPayNow = () => {
    const data = {
      num_months: this.state.numMonths,
    };
    this.setState({
      processing: true,
      error: false,
    });
    fetch(`${config.backend.url}${config.backend.payNowPath}`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.status === 200) {
          this.setState({
            step: 'response',
            success: true,
            processing: false,
          });
        } else {
          this.setState({
            step: 'response',
            processing: false,
            error: true,
          });
        }
      })
      .catch(err => {
        this.setState({
          step: 'response',
          processing: false,
          error: true,
        });
      });
  };
  public handlePayNow = () => {
    this.setState({
      opened: true,
    });
  };
  public handleChange = (event: { target: { value: any } }) => {
    this.setState({
      numMonths: Number(event.target.value),
    });
  };
  public render() {
    const {
      classes,
      monthlyPaymentAmount,
      nextPaymentMonthOptions,
      paymentMethods,
      nextPaymentMonth,
      onClose,
    } = this.props;
    const defaultMethod = paymentMethods.find(method => method.isDefault);
    return (
      <Grid container direction="column" className={classes.root}>
        {this.state.step == 'details' && (
          <>
            <Typography align="center" variant="h3" className={classes.title}>
              Pay Now
            </Typography>
            <Typography gutterBottom variant="body1">
              Your next monthly payment is on {nextPaymentMonth}
            </Typography>
            <Typography gutterBottom variant="body1">
              You can choose to either pay now or wait for your designated payment date. You can also pay for the next
              few months in advance.
            </Typography>
            <div className={classes.smallSpacer} />
            <Typography gutterBottom variant="subtitle1">
              Pay for:
            </Typography>
            <Select
              value={this.state.numMonths}
              onChange={this.handleChange}
              input={<OutlinedInput labelWidth={0} name="numMonths" id="outlined-age-simple" />}
            >
              {nextPaymentMonthOptions &&
                nextPaymentMonthOptions.map(item => (
                  <MenuItem key={JSON.parse(item).month} value={JSON.parse(item).month + 1}>
                    {JSON.parse(item).payment_str}
                  </MenuItem>
                ))}
            </Select>
            <div className={classes.smallSpacer} />
            <Typography align="center" gutterBottom variant="h6">
              Your Payment Now: ${monthlyPaymentAmount && monthlyPaymentAmount * this.state.numMonths}
            </Typography>
            <div className={classes.smallSpacer} />
            <Grid item className={classes.buttonWrapper}>
              <Button variant="contained" color="primary" onClick={() => this.setState({ step: 'confirmation' })}>
                Make Payment Now
              </Button>
            </Grid>
          </>
        )}
        {this.state.step == 'confirmation' && (
          <>
            <Typography align="center" variant="h3" className={classes.title}>
              Confirm Payment
            </Typography>
            <div className={classes.smallSpacer} />
            <Typography align="center" gutterBottom variant="body1">
              Your Payment Now: ${monthlyPaymentAmount && monthlyPaymentAmount * this.state.numMonths}
            </Typography>
            <Typography align="center" gutterBottom variant="body1">
              Bank Name: {defaultMethod && defaultMethod.bankName}
            </Typography>
            <Typography align="center" gutterBottom variant="body1">
              Account number: *****{defaultMethod && defaultMethod.last4}
            </Typography>
            <div className={classes.smallSpacer} />
            <Typography align="center" gutterBottom variant="h6">
              The payment will be processed now. Please confirm.
            </Typography>
            <div className={classes.smallSpacer} />
            <Grid item className={classes.buttonWrapper}>
              <Button variant="contained" color="primary" onClick={() => this.setState({ step: 'details' })}>
                Cancel
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="contained"
                color="primary"
                disabled={this.state.processing}
                onClick={this.handleConfirmPayNow}
              >
                {this.state.processing && <CircularProgress size={20} />}
                Confirm Payment
              </Button>
            </Grid>
          </>
        )}
        {this.state.step == 'response' && (
          <>
            <Typography align="center" variant="h3" className={classes.title}>
              Payment Confirmation
            </Typography>
            <div className={classes.smallSpacer} />
            <Typography align="center" gutterBottom variant="h6">
              Your Payment is being processed. Check your email for your receipt and confirmation.
            </Typography>
            <div className={classes.smallSpacer} />
            <Grid item className={classes.buttonWrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setTimeout(() => (window.location.href = '/payments'), 1000);
                }}
              >
                OK
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PayNow);
