import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import { gql } from 'apollo-boost';
import * as React from 'react';

import { formatAsCurrency, formatAsLongDate } from '../utils';

import PaymentHistoryItemChart, {
  IPaymentHistoryItemChartFragment,
  PaymentHistoryItemChartFragment,
} from './PaymentHistoryItemChart';
import Typography from 'material-ui/styles/typography';

const styles = (theme: Theme) => ({
  listItemRoot: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit,
    width: 'auto',
    paddingRight: '0 !important',
    paddingLeft: '0 !important',
  },
  detailsRoot: {
    paddingRight: 0,
    paddingLeft: 0,
    flexDirection: 'column' as 'column',
  },
  primaryTypography: {
    fontWeight: 700,
  },
  secondaryTypography: {
    fontSize: 14,
    fontWeight: 700,
  },
  button: {
    marginTop: 20,
  },
  linkButton: {
    textDecoration: 'none',
  },
  expandButton: {
    width: 44,
    height: 44,
    minWidth: 0,
  },
  divider: {
    marginLeft: 35,
    marginRight: 35,
  },
  summaryContent: {
    display: 'block',
    margin: '1px 0px',
  },
  summaryRoot: {
    padding: 0,
  },
  expandedExpandButton: {
    transform: 'rotate(180deg)',
  },
  expansionRoot: {
    paddingLeft: 35,
    paddingRight: 35,
    '&:before': {
      backgroundColor: 'white',
    },
  },
});

export const PaymentHistoryItemFragment = gql`
  fragment PaymentHistoryItemFragment on Payment {
    id
    timestamp
    status
    startDate
    endDate
    amount
    url
    ...PaymentHistoryItemChartFragment
  }
  ${PaymentHistoryItemChartFragment}
`;

export interface IPaymentHistoryItemFragment extends IPaymentHistoryItemChartFragment {
  id: number;
  timestamp: string;
  status: string;
  startDate: string;
  endDate: string;
  amount: number;
  url: string;
}

interface Props extends WithStyles<typeof styles>, IPaymentHistoryItemFragment {
  divider: boolean;
}

interface State {
  expanded: boolean;
}

class PaymentHistoryItem extends React.Component<Props, State> {
  public static defaultProps = {
    divider: true,
  };
  public state = {
    expanded: false,
  };
  public handleChange = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  public render() {
    const { classes, divider, timestamp, status, startDate, endDate, amount, url, parts, id } = this.props;
    const { expanded } = this.state;
    const billingDate = formatAsLongDate(new Date(timestamp));
    const primaryText = status === 'Pending' ? `Upcoming payment for ${formatAsLongDate(new Date(startDate))}` : `${formatAsLongDate(new Date(startDate))}`;
    return (
      <div>
        <ExpansionPanel
          elevation={0}
          classes={{ root: classes.expansionRoot }}
          expanded={expanded}
          onChange={this.handleChange}
        >
          <ExpansionPanelSummary
            classes={{
              root: classes.summaryRoot,
              content: classes.summaryContent,
            }}
          >
            <ListItem className={classes.listItemRoot}>
              <ListItemText
                primary={primaryText}
                secondary={formatAsCurrency(amount)}
                primaryTypographyProps={{
                  className: classes.primaryTypography,
                }}
                secondaryTypographyProps={{
                  className: classes.secondaryTypography,
                }}
              />
              <Button className={classes.expandButton} variant="outlined" color="primary">
                <ExpandMoreIcon className={classnames(expanded && classes.expandedExpandButton)} />
              </Button>
            </ListItem>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails
            classes={{
              root: classes.detailsRoot,
            }}
          >
            Payment initiated on {billingDate}
            <PaymentHistoryItemChart id={id} parts={parts} />
            {url && (
              <a href={url} target="_blank" className={classes.linkButton} download>
                <Button variant="outlined" color="primary" className={classes.button}>
                  download summary
                </Button>
              </a>
            )}
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {divider && <Divider className={classes.divider} />}
      </div>
    );
  }
}

export default withStyles(styles)(PaymentHistoryItem);
