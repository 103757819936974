import Grid, { GridProps } from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import * as React from 'react';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
});

interface Props extends WithStyles<typeof styles> {}

class FlexGrid extends React.Component<Props & GridProps, {}> {
  public render() {
    const { classes, children, ...otherProps } = this.props;
    return (
      <Grid className={classes.root} {...otherProps}>
        {children}
      </Grid>
    );
  }
}

export default withStyles(styles)(FlexGrid);
