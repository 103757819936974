import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { gql } from 'apollo-boost';
import * as React from 'react';
import HomeValueChart, { HomeValueChartFragment, IHomeValueChartFragment } from './HomeValueChart';
import HousePropertyText from './HousePropertyText';
import Neighborhood from './Neighborhood';
import RoundedPaper from './RoundedPaper';

const styles = (theme: Theme) => ({
  additionalTitle: { marginLeft: '35px' },
  detailsContainer: {
    paddingLeft: '15px',
  },
  root: {
    marginTop: -theme.spacing.unit * 2,
  },
  sideBox: {
    padding: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 4,
  },
  documentsSideBox: {
    marginTop: -theme.spacing.unit,
    padding: theme.spacing.unit * 2,
    paddingTop: 0,
    paddingBottom: theme.spacing.unit * 4,
  },
  sideBoxContainer: {
    width: '100%',
    flexDirection: 'column' as 'column',
    flexWrap: 'nowrap' as 'nowrap', // no idea why, just CSS things
    [theme.breakpoints.only('sm')]: {
      flexDirection: 'row' as 'row',
    },
  },
  summaryRoot: {
    padding: 0,
    minHeight: 'initial !important',
  },
  summaryContent: {
    margin: 0,
    padding: 0,
  },
  summaryContentExpanded: {
    minHeight: 'initial !important',
    margin: '0 !important',
    padding: 0,
  },
  expandedExpandButton: {
    transform: 'rotate(180deg)',
    paddingLeft: 32,
    paddingRight: 32,
  },
});

export const HouseDetailsFragment = gql`
  fragment HouseDetailsFragment on Resident {
    id
    homeDocuments {
      url
      name
    }
    property {
      id
      home {
        id
        notes
        homeDetails {
          id
          bathrooms
          bedrooms
          yearBuilt
          finishedSqft
          roofAge
          roofType
        }
        neighborhood {
          id
          name
          population
          imageUrl
        }
      }
      ...HomeValueChartFragment
    }
  }
  ${HomeValueChartFragment}
`;

export interface IHouseDetailsFragment {
  homeDocuments: {
    name: string;
    url: string;
  }[];
  property: {
    id: number;
    homeId: number;
    home: {
      notes: string;
      homeDetails: {
        bathrooms: string;
        bedrooms: string;
        yearsBuilt: string;
        finishedSqft: string;
        roofAge: string;
        roofType: string;
        additional: {
          label: string;
          value: string;
        }[];
      };
      neighborhood: {
        name: string;
        imageUrl: string;
        population: string;
      };
    };
  } & IHomeValueChartFragment;
}

interface Props extends WithStyles<typeof styles, true>, IHouseDetailsFragment {}

const INITIAL_DOCUMENTS_SHOW_COUNT = 3;
class HouseDetails extends React.Component<Props, {}> {
  public state = {
    expanded: false,
  };

  public handleChange = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  public render() {
    const details: any = {
      yearBuilt: 'Year Built',
      finishedSqft: 'Lot Size (sqft)',
      bathrooms: 'Bathrooms',
      bedrooms: 'Bedrooms',
      // roofAge: 'Roof age',
      // roofType: 'Roof type',
    };

    const { classes, theme, property, homeDocuments } = this.props;
    const { expanded } = this.state;
    return (
      <Grid container>
        <Grid item md={6}>
          <Grid container className={classes.detailsContainer}>
            {Object.keys(details).map(detail => (
              <HousePropertyText
                key={detail}
                label={details[detail]}
                value={(property.home.homeDetails as any)[detail]}
              />
            ))}
            {/* <HousePropertyText big label="Documents" />
            <Grid item xs={12} className={classes.documentsSideBox}>
              {homeDocuments.slice(0, INITIAL_DOCUMENTS_SHOW_COUNT).map(file => (
                <a href={file.url} key={file.name} target="_blank">
                  <Typography>{file.name}</Typography>
                </a>
              ))}
              {homeDocuments.slice(INITIAL_DOCUMENTS_SHOW_COUNT, expanded ? undefined : 0).map(file => (
                <a href={file.url} key={file.name} target="_blank">
                  <Typography>{file.name}</Typography>
                </a>
              ))}
              {homeDocuments.length > INITIAL_DOCUMENTS_SHOW_COUNT && (
                <Button color="primary" onClick={this.handleChange}>
                  {expanded ? 'show less' : 'show more'}
                </Button>
              )}
            </Grid> */}
          </Grid>
          <Typography gutterBottom variant="h6" className={classes.additionalTitle}>
            Additional Details
          </Typography>
          <Grid container className={classes.detailsContainer}>
            {/* {property.home.homeDetails.additional.map(detail => (
              <HousePropertyText key={detail.label} label={detail.label} value={detail.value} />
            ))} */}
            <HousePropertyText big label="Notes" value={property.home.notes} />
          </Grid>
        </Grid>
        <Grid item md={6} container className={classes.sideBoxContainer}>
          <Grid item className={classes.sideBox} sm={6} md={12}>
            <RoundedPaper shadow={true} paddingTop={0}>
              <Neighborhood {...property.home.neighborhood} />
            </RoundedPaper>
          </Grid>
          <Grid item className={classes.sideBox} sm={6} md={12}>
            <RoundedPaper shadow={true}>
              <HomeValueChart small={true} {...property} />
            </RoundedPaper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HouseDetails);
