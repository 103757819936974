import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Carousel from 're-carousel';
import * as React from 'react';

import BackgroundImage from '../components/BackgroundImage';
import CarouselArrows from '../components/CarouselArrows';
import CarouselIndicatorDots from '../components/CarouselIndicatorDots';
import FlexGrid from '../components/FlexGrid';
import HomeValueChart from '../components/HomeValueChart';
import HouseDetailsText from '../components/HouseDetailsText';
import LoadingIndicator from '../components/LoadingIndicator';
import MainGridContainer from '../components/MainGridContainer';
import MonthlyPaymentChart from '../components/MonthlyPaymentChart';
import Notifications from '../components/Notifications';
import OwnershipAccrualChart from '../components/OwnershipAccrualChart';
import withQueryAndBackground, { WithQuery } from '../components/PageQuery';
import RoundedPaper from '../components/RoundedPaper';
import SupportContactFragment from '../components/SupportContact';

import MainPageQuery, { MainPageQueryData } from './MainPageQuery';

const styles = (theme: Theme) => ({
  rightColumn: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: `${theme.spacing.unit * 4}px !important`,
    },
  },
  large: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  carousel: {
    '&>div': {
      display: 'flex',
    },
    [theme.breakpoints.down('md')]: {
      height: '500px !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: '300px !important',
    },
  },
});

interface Props extends WithStyles<typeof styles>, WithQuery<MainPageQueryData> {}

class MainPage extends React.Component<Props, {}> {
  public renderCarousel() {
    const { data, classes } = this.props;
    return (
      <Carousel widgets={[CarouselIndicatorDots, CarouselArrows]} className={classes.carousel}>
        {data && data.resident && <HomeValueChart {...data.resident.property} />}
        {data && data.resident && <OwnershipAccrualChart {...data.resident.savingsBuilder} />}
      </Carousel>
    );
  }

  public renderLarge() {
    const { data, loading, classes } = this.props;
    return (
      <>
        <FlexGrid item xs={12} lg={4}>
          <LoadingIndicator type="emptySpace" loading={loading}>
            {data && data.resident && <HouseDetailsText {...data.resident} />}
          </LoadingIndicator>

          {/* <RoundedPaper grow shadow title="Notifications">
            <LoadingIndicator type="list" loading={loading}>
              {data && data.resident && <Notifications {...data.resident} />}
            </LoadingIndicator>
          </RoundedPaper> */}
        </FlexGrid>

        <FlexGrid item xs={12} lg={8}>
          <RoundedPaper grow flex shadow marginBottom={40}>
            <LoadingIndicator type="textAndLinearChart" loading={loading}>
              {/* {this.renderCarousel()} */}
              {data && data.resident && <OwnershipAccrualChart {...data.resident.savingsBuilder} />}
            </LoadingIndicator>
          </RoundedPaper>

          <Grid container spacing={40}>
            <FlexGrid item xs={12} md={6}>
              <RoundedPaper padding grow shadow flex title="Monthly payment">
                <LoadingIndicator type="textAndRadialChart" loading={loading} flexBasis={0}>
                  {data && data.resident && <MonthlyPaymentChart {...data.resident} />}
                </LoadingIndicator>
              </RoundedPaper>
            </FlexGrid>
            <FlexGrid item xs={12} md={6}>
              <RoundedPaper padding grow flex shadow>
                <SupportContactFragment />
              </RoundedPaper>
            </FlexGrid>
          </Grid>
        </FlexGrid>
      </>
    );
  }

  public renderSmall() {
    const { data, loading, classes } = this.props;
    return (
      <>
        <FlexGrid item xs={12}>
          <LoadingIndicator type="emptySpace" loading={loading}>
            {data && <HouseDetailsText {...data.resident} />}
          </LoadingIndicator>
        </FlexGrid>

        <FlexGrid item xs={12}>
          <RoundedPaper grow flex shadow marginBottom={40}>
            <LoadingIndicator type="textAndLinearChart" loading={loading}>
              {this.renderCarousel()}
            </LoadingIndicator>
          </RoundedPaper>
        </FlexGrid>

        <FlexGrid item xs={12}>
          {/* <RoundedPaper grow flex shadow title="Notifications">
            <LoadingIndicator type="list" loading={loading}>
              {data && <Notifications {...data.resident} />}
            </LoadingIndicator>
          </RoundedPaper> */}
        </FlexGrid>

        <FlexGrid item xs={12} md={6}>
          <RoundedPaper padding grow flex shadow title="Monthly payment">
            <LoadingIndicator type="textAndRadialChart" loading={loading} flexBasis={0}>
              {data && <MonthlyPaymentChart {...data.resident} />}
            </LoadingIndicator>
          </RoundedPaper>
        </FlexGrid>

        <FlexGrid item xs={12} md={6}>
          <RoundedPaper padding grow flex shadow>
            <SupportContactFragment />
          </RoundedPaper>
        </FlexGrid>
      </>
    );
  }

  public render() {
    const { backgroundData, data, loading, error, classes } = this.props;

    return (
      <>
        <BackgroundImage {...backgroundData} />
        <MainGridContainer reverse margin flex>
          <Hidden mdDown={true}>{this.renderLarge()}</Hidden>
          <Hidden lgUp={true}>{this.renderSmall()}</Hidden>
        </MainGridContainer>
      </>
    );
  }
}

export default withQueryAndBackground<MainPageQueryData>(MainPageQuery, withStyles(styles)(MainPage));
