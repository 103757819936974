import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

const styles = (theme: Theme) => ({
  root: {
    padding: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing.unit * 2,
    },
  },
  coverPhoto: {
    height: 200,
    margin: -theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    [theme.breakpoints.down('sm')]: {
      margin: -theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2,
    },
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  buttonWrapper: {
    textAlign: 'center' as 'center',
    marginTop: theme.spacing.unit * 2,
  },
  title: {
    marginBottom: theme.spacing.unit * 2,
  },
});

export interface INotificationDetailsFragment {
  body: string;
  coverImageUrl?: string;
  ctaLabel?: string;
  ctaUrl?: string;
  subject: string;
  title: string;
}

interface Props extends WithStyles<typeof styles>, INotificationDetailsFragment {}

class NotificationDetails extends React.Component<Props, {}> {
  public render() {
    const { body, classes, coverImageUrl, ctaLabel, ctaUrl, subject, title } = this.props;
    return (
      <Grid container direction="column" className={classes.root}>
        {coverImageUrl && (
          <Grid item className={classes.coverPhoto} style={{ backgroundImage: `url(${coverImageUrl}` }} />
        )}
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <p>{subject}</p>
        <p>{body}</p>
        {ctaUrl && ctaLabel && (
          <Grid item className={classes.buttonWrapper}>
            <Button variant="contained" color="primary" href={ctaUrl} target="_blank">
              {ctaLabel}
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(NotificationDetails);
