import * as React from "react";
import * as ReactDOM from "react-dom";
import FullStory from "react-fullstory";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import "./index.css";
import config from "./config";
import { unregister } from "./registerServiceWorker";

unregister();

const render = (Component: any) => {
  return ReactDOM.render(
    <BrowserRouter>
      {config.env === "prod" && <FullStory org="KDZJD" />}
      <Component />
    </BrowserRouter>,
    document.getElementById("root")
  );
};

render(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    render(NextApp);
  });
}
