import Grid from '@material-ui/core/Grid';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Color from 'color';
import { gql } from 'apollo-boost';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { gradients } from '../Theme';
import { formatAsCurrency, formatAsMonthAndYear } from '../utils';

const styles = (theme: Theme) => ({
  chart: {
    flexGrow: 1,
    flexBasis: 0,
  },
  chartContainer: {
    padding: '10px 40px 10px 40px',
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    flexGrow: 1,
  },
  chartContainerSmall: {
    padding: 0,
  },
  header: {
    marginBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },
  trend: {
    textAlign: 'center' as 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  trendArrow: {
    display: 'block',
    margin: 'auto',
    fontSize: '2em',
    fontWeight: 200,
  },
  value: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 36,
    },
  },
  valueDescription: {
    color: theme.palette.grey[500],
  },
});

export const RightToPurchaseChartFragment = gql`
  fragment RightToPurchaseChartFragment on SavingsBuilder {
    id
    rtpType
    planType
    rightToPurchaseChart {
      date
      value
    }
  }
`;

export interface IRightToPurchaseChartFragment {
  rtpType: 'Quaterly' | 'Monthly' | 'Yearly';
  planType: 'Fixed Growth' | 'Legacy' | 'Fair Market Value';
  rightToPurchaseChart: {
    date: string;
    value: number;
  }[];
}
interface Props extends WithStyles<typeof styles, true>, IRightToPurchaseChartFragment {
  small?: boolean;
}

class RightToPurchaseChart extends React.Component<Props, {}> {
  public renderHeader() {
    const { classes, theme, small } = this.props;
    const currRTP = this.getCurrentRTP();

    return (
      <Grid item className={classes.header} style={{ marginBottom: small ? theme.spacing.unit * 2 : undefined }}>
        <Typography color="secondary" variant={small ? 'h2' : 'h1'} gutterBottom className={classes.value}>
          {formatAsCurrency(currRTP.value)}
        </Typography>
        {!small && (
          <Typography color="secondary" variant="body1" gutterBottom className={classes.valueDescription}>
            Right to purchase as of {formatAsMonthAndYear(new Date(currRTP.date))}
          </Typography>
        )}
      </Grid>
    );
  }

  public renderChart() {
    const { classes, small, rtpType, theme } = this.props;
    return (
      <div className={classes.chart}>
        <Bar
          data={this.getChartData}
          options={{
            maintainAspectRatio: small,
            legend: { display: false },
            tooltips: {
              mode: 'nearest',
              intersect: true,
              displayColors: false,
              backgroundColor: theme.palette.primary.main,
              callbacks: {
                label: (tooltipItem, chart) => formatAsCurrency(Number(tooltipItem.yLabel)),
                title: () => '',
              },
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    maxTicksLimit: 4,
                    callback: value => {
                      value = parseInt(value) / 1000000;
                      return `${value}m`;
                    },
                    fontColor: '#c7c7c7',
                    fontSize: 14,
                  },
                  gridLines: { color: 'rgba(0, 0, 0, 0.05)', zeroLineWidth: 0 },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1,
                  categoryPercentage: 1,
                  gridLines: { display: false },
                  ticks: { fontColor: '#c7c7c7', fontSize: 14 },
                  type: 'time',
                  bounds: 'ticks',
                  time: {
                    unit: (({
                      Yearly: 'year',
                      Quarterly: 'quarter',
                      Monthly: 'month',
                    } as any)[rtpType] || 'month') as 'year' | 'quarter' | 'month',
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }
  public render() {
    const { classes, small } = this.props;
    return (
      <div className={small ? classes.chartContainerSmall : classes.chartContainer}>
        {this.renderHeader()}
        {this.renderChart()}
      </div>
    );
  }

  private getCurrentRTP() {
    return (
      this.props.rightToPurchaseChart
        .slice()
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
        .reverse()
        .find(({ date }) => new Date(date).getTime() < new Date().getTime()) ||
      this.props.rightToPurchaseChart.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())[0]
    );
  }

  private getGradientOfOpacity(ctx: any, fade: number) {
    const gradient = ctx.createLinearGradient(0, 0, 0, 200);
    gradient.addColorStop(
      0,
      Color(gradients.secondary[1])
        .fade(fade)
        .toString(),
    );
    gradient.addColorStop(
      1,
      Color(gradients.secondary[0])
        .fade(fade)
        .toString(),
    );
    return gradient;
  }

  private getChartData = (canvas: any) => {
    const { theme, rightToPurchaseChart } = this.props;
    const ctx = canvas.getContext('2d');
    const historyGradient = this.getGradientOfOpacity(ctx, 0.6);
    const futureGradient = this.getGradientOfOpacity(ctx, 0);
    const data = {
      datasets: [
        {
          data: rightToPurchaseChart
            .filter(({ date }) => new Date(date).getTime() < new Date().getTime())
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            .map(({ date, value }) => ({
              x: new Date(date),
              y: value,
            })),

          backgroundColor: historyGradient,
          label: 'past',
        },
        {
          data: rightToPurchaseChart
            .filter(({ date }) => new Date(date).getTime() > new Date().getTime())
            .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
            .map(({ date, value }) => ({
              x: new Date(date),
              y: value,
            })),
          backgroundColor: futureGradient,
          label: 'future',
        },
      ],
    };
    return data;
  };
}

export default withStyles(styles, { withTheme: true })(RightToPurchaseChart);
