import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { ServerError } from "apollo-link-http-common";

import config from "../config";
import { errorHandler } from "../utils";

const client = new ApolloClient({
  link: new HttpLink({
    uri: `${config.backend.url}${config.backend.apiPath}`,
    credentials: "include",
  }),
  cache: new InMemoryCache({ addTypename: true }),
});

export default client;
