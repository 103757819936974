import { StackdriverErrorReporter } from "stackdriver-errors-js";
import * as StackTrace from "stacktrace-js";

import config from "./config";

(window as any).StackTrace = StackTrace;

export function formatAsCurrency(
  value: number | undefined,
  hasDecimals = false,
  unit = "$",
) {
  if (!value) return "";
  const result = `${unit}${value
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  if (hasDecimals) return result;
  return result.split(".")[0];
}

export function formatAsMonthAndYear(date: Date) {
  return `${(date.getMonth() + 1).toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}/${date.getFullYear()}`;
}

export function formatAsPercentage(value: number) {
  return `${value.toFixed(2)}%`;
}

export function formatAsLongDate(date: Date) {
  return date.toLocaleDateString("en-US");
}

const environment = process.env.NODE_ENV;

export let errorHandler: any;

if (environment === "production") {
  errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: config.stackdriver.apiKey,
    projectId: config.stackdriver.projectID,
    service: "frontend",
  });
} else {
  errorHandler = { report: console.error, setUser: (username: string) => null };
}
