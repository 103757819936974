import { gql } from 'apollo-boost';

export interface PropertyValue {
  id: number;
  timestamp: string;
  startDate: string;
  endDate: string;
  value: number;
}

export const PropertyValueFragment = gql`
  fragment PropertyValueFragment on HomeValue {
    id
    timestamp
    startDate
    endDate
    value
  }
`;
