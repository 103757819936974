import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { gql } from 'apollo-boost';
import * as React from 'react';

const styles = (theme: Theme) => ({
  root: {
    marginTop: -theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit,
  },
  document: {
    margin: 25,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    padding: theme.spacing.unit,
    borderRadius: 4,
    width: 'auto',
  },
  primaryTypography: {
    fontWeight: 700,
  },
  secondaryTypography: {
    fontSize: 14,
  },
  avatar: {
    borderRadius: 4,
    height: 48,
    width: 56,
  },
  divider: {
    marginLeft: 35,
    marginRight: 35,
  },
  button: {
    width: 44,
    height: 44,
    minWidth: 0,
  },
});

export const FinancialDocumentsFragment = gql`
  fragment FinancialDocumentsFragment on Resident {
    residentDocuments {
      id
      url
      name
      documentType
    }
  }
`;

export interface IFinancialDocumentsFragment {
  residentDocuments: {
    id: string;
    url: string;
    name: string;
    documentType: string;
  }[];
}

interface Props extends WithStyles<typeof styles>, IFinancialDocumentsFragment { }

function ListItemLink(props: any) {
  return <ListItem button component="a" {...props} />;
}

class FinancialDocuments extends React.Component<Props, {}> {
  public renderDocument(url: string, name: string, divider: boolean) {
    const { classes } = this.props;

    return (
      <>
        <ListItemLink className={classes.document} href={url} target="_blank">
          <div>
            <Button className={classes.button} variant="outlined" color="primary">
              <ArrowDownwardIcon />
            </Button>
          </div>
          <ListItemText
            primary={name}
            primaryTypographyProps={{
              className: classes.primaryTypography,
            }}
            secondaryTypographyProps={{
              className: classes.secondaryTypography,
            }}
          />
        </ListItemLink>
        {divider && <Divider className={classes.divider} />}
      </>
    );
  }

  public render() {
    const { classes, residentDocuments } = this.props;
    return (
      <List className={classes.root}>
        {residentDocuments.map((document, i) => (
          <div key={document.url}>
            {this.renderDocument(document.url, document.documentType, i < residentDocuments.length - 1)}
          </div>
        ))}
      </List>
    );
  }
}

export default withStyles(styles)(FinancialDocuments);
