import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ApolloError } from 'apollo-boost';
import { ServerError } from 'apollo-link-http-common';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import EmoticonSadOutlineIcon from 'mdi-react/EmoticonSadOutlineIcon';
import * as React from 'react';
import { Link } from 'react-router-dom';

import FlexGrid from '../components/FlexGrid';
import MainGridContainer from '../components/MainGridContainer';
import config from '../config';
import { errorHandler } from '../utils';

const styles = (theme: Theme) => ({
  container: {
    textAlign: 'center' as 'center',
  },
  icon: {
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
    verticalAlign: 'middle',
  },
  link: {
    textDecoration: 'none',
  },
  message: {
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  subMessage: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

interface Props extends WithStyles<typeof styles> {
  error: ApolloError;
  redirectsOnly: boolean;
}

class ErrorPage extends React.Component<Props, {}> {
  public static defaultProps = {
    redirectsOnly: false,
  };
  public render() {
    const { classes, error, redirectsOnly } = this.props;
    let isUnauthorisedError = false;
    if (error.networkError && (error.networkError as ServerError).statusCode === 401) {
      window.location.href = `${config.backend.url}${config.backend.loginPath}`;
      isUnauthorisedError = true;
    } else if (error) {
      errorHandler.report(error);
    }
    if (redirectsOnly) return null;
    return (
      <>
        <MainGridContainer reverse margin flex>
          <FlexGrid item xs={12} className={classes.container}>
            {isUnauthorisedError ? (
              <>
                <CircularProgress color="primary" />
              </>
            ) : (
              <>
                <Typography variant="h2" className={classes.message}>
                  Something went wrong
                  <EmoticonSadOutlineIcon size={32} className={classes.icon} />
                </Typography>
                <Typography variant="h2" className={classes.subMessage}>
                  <Link to="/" className={classes.link}>
                    <ArrowLeftIcon size={32} className={classes.icon} />
                    Back to main page
                  </Link>
                </Typography>
              </>
            )}
          </FlexGrid>
        </MainGridContainer>
      </>
    );
  }
}

export default withStyles(styles)(ErrorPage);
