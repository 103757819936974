import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

const styles = (theme: Theme) => ({
  button: {
    marginTop: theme.spacing.unit,
  },
  buttonWrapper: {
    textAlign: "center" as "center",
  },
});

interface Props extends WithStyles<typeof styles> {}

class SupportContact extends React.Component<Props, {}> {
  public render() {
    const { classes } = this.props;
    return (
      <>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              alt="Remy Sharp"
              src="https://flyhomes.com/website/support.png"
            />
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={<Typography variant="h6">Contact Flyhomes aka ZeroDown</Typography>}
          />
        </ListItem>
        <Typography variant="body2">
          Flyhomes aka ZeroDown team is here to help you with any problems. Request repairs
          or inquire about purchase-credits valuation at any time.
          <br></br>
          By dropping an email at <b><a href="mailto:help@flyhomes.com">help@flyhomes.com</a></b>
        </Typography>
      </>
    );
  }
}

export default withStyles(styles)(SupportContact);
